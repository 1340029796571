import {
  AccountAllDetailCollection,
  AccountingPeriod,
  AccountingPeriodCollection,
  CalculatedPriceTypeCollection,
  ChargeTypeCollection,
  ChargeTypeDetailCollection,
  ChargeTypeDropdownDictionary,
  ChargeTypeSummary,
  IndexRate,
  IndexRateDetail,
  IndexRateSummaryCollection,
  Invoice,
  InvoiceAttachmentCollection,
  InvoiceDetailCollection,
  InvoiceDetailTotalCollection,
  InvoiceError,
  InvoiceFilterCollection,
  InvoiceGroup,
  InvoiceGroupCollection,
  InvoiceSummary,
  MasterChargeType,
  MasterChargeTypeDetail,
  PalDailyRateRequest,
  TariffRate,
  TariffRateDetailCollection,
  TariffRateDropdownDictionary,
  TariffRateSummary,
  TariffRateVersionWorkflowAction,
  TspChargeTypeCollection,
  TspChargeTypeDetail,
  Zone,
  ZoneToZone,
} from '@gms/billing-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IAccountPeriod } from 'app/modules/accounting/utils/journal-entries-utils';
import {
  ChargeTypesListNavigationState,
  InvoiceExceptionType,
} from 'app/store/billing/billing.utils';
import { RetryableAction } from 'app/store/retry/retry.actions';

export enum EBillingActions {
  ADD_INDEX_RATE = '[BILLING] add Index Rate',
  ADD_INDEX_RATE_FAILURE = '[BILLING] add Index Rate Error',
  ADD_INDEX_RATE_SUCCESS = '[BILLING] add Index Rate Success',
  ADD_MASTER_CHARGE_TYPE = '[BILLING] add Master Charge Type',
  ADD_MASTER_CHARGE_TYPE_ERROR = '[BILLING] add Master Charge Type Error',
  ADD_MASTER_CHARGE_TYPE_SUCCESS = '[BILLING] add Master Charge Type Success',
  ADD_TARIFF_RATE = '[BILLING] add Tariff Rate',
  ADD_TARIFF_RATE_FAILURE = '[BILLING] add Tariff Rate Failure',
  ADD_TARIFF_RATE_SUCCESS = '[BILLING] add Tariff Rate Success',
  FETCH_TARIFF_RATES = '[BILLING] get TariffRates',
  FETCH_TARIFF_RATES_FAILURE = '[BILLING] get TariffRates Failure',
  FETCH_TARIFF_RATES_SUCCESS = '[BILLING] get TariffRates Success',
  FETCH_TARIFF_RATE_BY_ID = '[BILLING] fetch TariffRateById',
  FETCH_TARIFF_RATE_BY_ID_FAILURE = '[BILLING] fetch TariffRateById Failure',
  FETCH_TARIFF_RATE_BY_ID_SUCCESS = '[BILLING] fetch TariffRateById Success',
  FETCH_CHARGE_TYPES_DETAILS = '[BILLING] fetch ChargeTypesDetails',
  FETCH_CHARGE_TYPES_DETAILS_FAILURE = '[BILLING] fetch ChargeTypesDetails Failure',
  FETCH_CHARGE_TYPES_DETAILS_SUCCESS = '[BILLING] fetch ChargeTypesDetails Success',
  GET_CHARGE_TYPES = '[BILLING] get ChargeTypes',
  GET_CHARGE_TYPES_ERROR = '[BILLING] get ChargeTypes Error',
  GET_CHARGE_TYPES_SUCCESS = '[BILLING] get ChargeTypes Success',
  GET_CHARGE_TYPES_BY_ID = '[BILLING] get ChargeTypesById',
  GET_CHARGE_TYPES_BY_ID_ERROR = '[BILLING] get ChargeTypesById Error',
  GET_CHARGE_TYPES_BY_ID_SUCCESS = '[BILLING] get ChargeTypesById Success',
  GET_CHARGE_TYPES_DICTIONARY = '[BILLING] get Charge Type Dictionary',
  GET_CHARGE_TYPES_DICTIONARY_ERROR = '[BILLING] get Charge Type Dictionary Error',
  GET_CHARGE_TYPES_DICTIONARY_SUCCESS = '[BILLING] get Charge Type Dictionary Success',
  FETCH_CHARGE_TYPES_BY_ID = '[BILLING] fetch ChargeTypesById',
  FETCH_CHARGE_TYPES_BY_ID_FAILURE = '[BILLING] fetch ChargeTypesById Failure',
  FETCH_CHARGE_TYPES_BY_ID_SUCCESS = '[BILLING] fetch ChargeTypesById Success',
  FETCH_MULTIPLE_CHARGE_TYPES_BY_ID = '[BILLING] fetch MultipleChargeTypesById',
  FETCH_MULTIPLE_CHARGE_TYPES_BY_ID_FAILURE = '[BILLING] fetch MultipleChargeTypesById Failure',
  FETCH_MULTIPLE_CHARGE_TYPES_BY_ID_SUCCESS = '[BILLING] fetch MultipleChargeTypesById Success',
  GET_TARIFF_RATE_DICTIONARY = '[BILLING] get Tariff Rate Dictionary',
  GET_TARIFF_RATE_DICTIONARY_ERROR = '[BILLING] get Tariff Rate Dictionary Error',
  GET_TARIFF_RATE_DICTIONARY_SUCCESS = '[BILLING] get Tariff Rate Dictionary Success',
  RESET_MASTER_CHARGE_TYPE = '[BILLING] reset Master Charge Type',
  RESET_MASTER_CHARGE_TYPE_ERROR = '[BILLING] reset Master Charge Type Error',
  RESET_MASTER_CHARGE_TYPE_SUCCESS = '[BILLING] reset Master Charge Type Success',
  RESET_MASTER_CHARGE_TYPE_VIEW = '[BILLING] reset Master Charge Type View',
  FETCH_INVOICE_BY_ID = '[BILLING] Fetch Invoice By Id',
  FETCH_INVOICE_BY_ID_FAILURE = '[BILLING] Fetch Invoice By Id Failure',
  FETCH_INVOICE_BY_ID_SUCCESS = '[BILLING] Fetch Invoice By Id Success',
  FETCH_INVOICE_FILTERS = '[BILLING] Fetch Invoice Filters',
  FETCH_INVOICE_FILTERS_SUCCESS = '[BILLING] Fetch Invoice Filters Success',
  FETCH_INVOICE_FILTERS_FAILURE = '[BILLING] Fetch Invoice Filters Failure',
  FETCH_INVOICES = '[BILLING] Fetch fetch invoices',
  FETCH_INVOICES_FAILURE = '[BILLING] Fetch fetch invoices failure',
  FETCH_INVOICES_SUCCESS = '[BILLING] Fetch fetch invoices Success',
  FETCH_INVOICES_EXCEPTIONS = '[BILLING] Fetch invoices Exceptions',
  FETCH_INVOICES_EXCEPTIONS_FAILURE = '[BILLING] Fetch Invoices exceptions Failure',
  FETCH_INVOICES_EXCEPTIONS_SUCCESS = '[BILLING] Fetch Invoices exceptions Success',
  RESET_TARIFF_RATE = '[BILLING] reset Tariff Rate',
  SEARCH_DISQUALIFICATION_FLOW_CHARGE_TYPES = '[BILLING] search disqualification flow charge types',
  SEARCH_DISQUALIFICATION_FLOW_CHARGE_TYPES_SUCCESS = '[BILLING] search disqualification flow charge types success',
  SEARCH_SEGMENTATION_TOTALS_CHARGE_TYPES = '[BILLING] search segmentation totals charge types',
  SEARCH_SEGMENTATION_TOTALS_CHARGE_TYPES_SUCCESS = '[BILLING] search segmentation totals charge types success',
  SEARCH_CHARGE_TYPES = '[BILLING] search charge types',
  SEARCH_CHARGE_TYPES_SUCCESS = '[BILLING] search charge types Success',
  SEARCH_CHARGE_TYPES_FAILURE = '[BILLING] search charge types failure',
  UPDATE_MASTER_CHARGE_TYPE = '[BILLING] update Charge Type',
  UPDATE_MASTER_CHARGE_TYPE_FAILURE = '[BILLING] update Charge Type failure',
  UPDATE_MASTER_CHARGE_TYPE_SUCCESS = '[BILLING] update Charge Type Success',
  CLEAR_INVOICE_TRANSACTION_DETAILS = '[BILLING] clear invoice transaction details',
  FETCH_INVOICE_TRANSACTION_DETAILS = '[BILLING] fetch invoice transaction details',
  FETCH_INVOICE_TRANSACTION_DETAILS_SUCCESS = '[BILLING] fetch invoice transaction details Success',
  FETCH_INVOICE_TRANSACTION_DETAILS_FAILURE = '[BILLING] fetch invoice transaction details failure',
  FETCH_INVOICE_TRANSACTION_DETAILS_TOTAL = '[BILLING] fetch export invoice details total',
  FETCH_INVOICE_TRANSACTION_DETAILS_TOTAL_SUCCESS = '[BILLING] fetch export invoice details total success',
  FETCH_INVOICE_TRANSACTION_DETAILS_TOTAL_FAILURE = '[BILLING] fetch export invoice details total failure',
  CLEAR_INVOICE_TRANSACTION_DETAILS_TOTAL = '[BILLING] clear export invoice details total',
  UPDATE_TARIFF_RATE = '[BILLING] update Tariff Rate',
  UPDATE_TARIFF_RATE_SUCCESS = '[BILLING] update Tariff Rate Success',
  UPDATE_TARIFF_RATE_FAILURE = '[BILLING] update Tariff Rate failure',
  FETCH_EARLIEST_OPEN_ACCOUNTING_PERIOD = '[BILLING} fetch earliest open accounting period',
  FETCH_EARLIEST_OPEN_ACCOUNTING_PERIOD_SUCCESS = '[BILLING] fetch earliest open accounting period success',
  FETCH_EARLIEST_OPEN_ACCOUNTING_PERIOD_FAILURE = '[BILLING] fetch earliest open accounting period failure',
  FETCH_TARIFF_RATE_DETAILS = '[BILLING] fetch tariff rate details',
  FETCH_TARIFF_RATE_DETAILS_SUCCESS = '[BILLING] fetch tariff rate details success',
  FETCH_TARIFF_RATE_DETAILS_FAILURE = '[BILLING] fetch tariff rate details failure',
  FETCH_EXPORT_CHARGE_TYPES_DETAILS = '[BILLING] fetch export charge types details',
  FETCH_EXPORT_CHARGE_TYPES_DETAILS_SUCCESS = '[BILLING] fetch export charge types details success',
  FETCH_EXPORT_CHARGE_TYPES_DETAILS_FAILURE = '[BILLING] fetch export charge types details failure',
  CLEAR_EXPORT_CHARGE_TYPES_DETAILS = '[BILLING] clear export charge types details',
  FETCH_EXPORT_INVOICE_DETAILS = '[BILLING] fetch export invoice details',
  FETCH_EXPORT_INVOICE_DETAILS_SUCCESS = '[BILLING] fetch export invoice details success',
  FETCH_EXPORT_INVOICE_DETAILS_FAILURE = '[BILLING] fetch export invoice details failure',
  CLEAR_EXPORT_INVOICE_DETAILS = '[BILLING] clear export invoice details',
  FETCH_EXPORT_INVOICE_EXCEPTIONS = '[BILLING] fetch export invoice exceptions',
  FETCH_EXPORT_INVOICE_EXCEPTIONS_SUCCESS = '[BILLING] fetch export invoice exceptions success',
  FETCH_EXPORT_INVOICE_EXCEPTIONS_FAILURE = '[BILLING] fetch export invoice exceptions failure',
  CLEAR_EXPORT_INVOICE_EXCEPTIONS = '[BILLING] clear export invoice exceptions',
  FETCH_EXPORT_TARIFF_RATE_DETAILS = '[BILLING] fetch export tariff rate details',
  FETCH_EXPORT_TARIFF_RATE_DETAILS_SUCCESS = '[BILLING] fetch export tariff rate details success',
  FETCH_EXPORT_TARIFF_RATE_DETAILS_FAILURE = '[BILLING] fetch export tariff rate details failure',
  CLEAR_EXPORT_TARIFF_RATE_DETAILS = '[BILLING] clear tariff rate details',
  FETCH_TSP_CHARGE_TYPE = '[BILLING] fetch tspChargeTypesById',
  FETCH_TSP_CHARGE_TYPE_SUCCESS = '[BILLING] fetch tspChargeTypesById success',
  FETCH_TSP_CHARGE_TYPE_FAILURE = '[BILLING] fetch tspChargeTypesById failure',
  FETCH_MULTIPLE_TSP_CHARGE_TYPES_BY_ID = '[BILLING] fetch MultipleTspChargeTypesById',
  FETCH_MULTIPLE_TSP_CHARGE_TYPES_BY_ID_FAILURE = '[BILLING] fetch MultipleTspChargeTypesById failure',
  FETCH_MULTIPLE_TSP_CHARGE_TYPES_BY_ID_SUCCESS = '[BILLING] fetch MultipleTspChargeTypesById success',
  FETCH_TSP_RESERVATION_CHARGE_TYPES = '[BILLING] fetch tspReservationChargeTypes',
  FETCH_TSP_RESERVATION_CHARGE_TYPES_SUCCESS = '[BILLING] fetch tspReservationChargeTypes success',
  FETCH_TSP_RESERVATION_CHARGE_TYPES_FAILURE = '[BILLING] fetch tspReservationChargeTypes failure',
  FETCH_INVOICE_GROUPS_BY_ID = '[BILLING] Fetch invoice groups',
  FETCH_INVOICE_GROUPS_BY_ID_FAILURE = '[BILLING] Fetch invoice groups failure',
  FETCH_INVOICE_GROUPS_BY_ID_SUCCESS = '[BILLING] Fetch invoice groups Success',
  ADD_INVOICE_GROUP = '[BILLING] add invoice group',
  ADD_INVOICE_GROUP_FAILURE = '[BILLING] add invoice group failure',
  ADD_INVOICE_GROUP_SUCCESS = '[BILLING] add invoice group success',
  RESET_INVOICE_GROUP = '[BILLING] reset invoice group',
  FETCH_INVOICE_GROUP_BY_ID = '[BILLING] Fetch invoice group by invoice group id',
  FETCH_INVOICE_GROUP_BY_ID_SUCCESS = '[BILLING] Fetch invoice group by invoice group id success',
  FETCH_INVOICE_GROUP_BY_ID_FAILURE = '[BILLING] Fetch invoice group by invoice group id failure',
  UPDATE_INVOICE_GROUP = '[BILLING] update invoice group',
  UPDATE_INVOICE_GROUP_FAILURE = '[BILLING] update invoice group failure',
  UPDATE_INVOICE_GROUP_SUCCESS = '[BILLING] update invoice group success',
  FETCH_ALL_OPEN_ACCOUNTING_PERIODS = '[BILLING] fetch all open accounting periods',
  FETCH_ALL_OPEN_ACCOUNTING_PERIODS_FAILURE = '[BILLING] fetch all open accounting periods failure',
  FETCH_ALL_OPEN_ACCOUNTING_PERIODS_SUCCESS = '[BILLING] fetch all open accounting periods success',
  SET_CHARGE_TYPES_NAVIGATION_STATE = '[BILLING] set charge types navigation state',
  UPDATE_TARIFF_RATE_WORKFLOW = '[BILLING] update tariff rate workflow status',
  UPDATE_TARIFF_RATE_WORKFLOW_FAILURE = '[BILLING] update tariff rate workflow status failure',
  UPDATE_TARIFF_RATE_WORKFLOW_SUCCESS = '[BILLING] update tariff rate workflow status success',
  CLEAR_TARIFF_RATE_WORKFLOW = '[BILLING] clear tariff rate workflow store',
  FETCH_INDEX_RATE = '[BILLING] fetch index rate',
  FETCH_INDEX_RATE_SUCCESS = '[BILLING] fetch index rate success',
  FETCH_INDEX_RATE_FAILURE = '[BILLING] fetch index rate failure',
  CLEAR_INDEX_RATE = '[BILLING] clear index rate',
  FETCH_CALCULATED_PRICE_TYPES = '[BILLING] fetch calculated price types',
  FETCH_CALCULATED_PRICE_TYPES_SUCCESS = '[BILLING] fetch calculated price types success',
  FETCH_CALCULATED_PRICE_TYPES_FAILURE = '[BILLING] fetch calculated price types failure',
  FETCH_INDEX_RATES = '[BILLING] fetch index rates',
  FETCH_INDEX_RATES_SUCCESS = '[BILLING] fetch index rates success',
  FETCH_INDEX_RATES_FAILURE = '[BILLING] fetch index rates failure',
  FETCH_ACCOUNTS = '[BILLING] fetch accounts',
  FETCH_ACCOUNTS_SUCCESS = '[BILLING] fetch accounts success',
  FETCH_ACCOUNTS_FAILURE = '[BILLING] fetch accounts failure',
  FETCH_INVOICE_ATTACHMENTS = '[BILLING] fetch invoice attachments',
  FETCH_INVOICE_ATTACHMENTS_SUCCESS = '[BILLING] fetch invoice attachments success',
  FETCH_INVOICE_ATTACHMENTS_FAILURE = '[BILLING] fetch invoice attachments failure',
  DELETE_INVOICE_ATTACHMENT = '[BILLING] delete invoice attachment',
  DELETE_INVOICE_ATTACHMENT_SUCCESS = '[BILLING] delete invoice attachment success',
  DELETE_INVOICE_ATTACHMENT_FAILURE = '[BILLING] delete invoice attachment failure',
  UPLOAD_INVOICE_ATTACHMENT = '[BILLING] Upload invoice attachment',
  UPLOAD_INVOICE_ATTACHMENT_SUCCESS = '[BILLING] Upload invoice attachment success',
  UPLOAD_INVOICE_ATTACHMENT_FAILURE = '[BILLING] Upload invoice attachment failure',
  DOWNLOAD_INVOICE_PACKAGE = '[BILLING] Download Invoice Package',
  DOWNLOAD_INVOICE_PACKAGE_SUCCESS = '[BILLING] Download Invoice Package success',
  DOWNLOAD_INVOICE_PACKAGE_FAILURE = '[BILLING] Download Invoice Package failure',
  GET_PAL_DAILY_BILLING_RATE = '[BILLING] Get PAL Daily Rate',
  GET_PAL_DAILY_BILLING_RATE_SUCCESS = '[BILLING] Get PAL Daily Rate success',
  GET_PAL_DAILY_BILLING_RATE_FAILURE = '[BILLING] Get PAL Daily Rate failure',
  GET_ACCOUNTING_PERIODS = '[BILLING] Get Accounting Periods',
  GET_ACCOUNTING_PERIODS_SUCCESS = '[BILLING] Get Accounting Periods Success',
  GET_ACCOUNTING_PERIODS_FAILURE = '[BILLING] Get Accounting Periods Failure',
  FETCH_CAP_REL_INDEX_RATES = '[BILLING] fetch capacity release index rates',
  FETCH_CAP_REL_INDEX_RATES_SUCCESS = '[BILLING] fetch capacity release index rates success',
  FETCH_CAP_REL_INDEX_RATES_FAILURE = '[BILLING] fetch capacity release index rates failure',
  FETCH_DELIVERY_ZONES = '[BILLING] Fetch Delivery Zones',
  FETCH_DELIVERY_ZONES_SUCCESS = '[BILLING] Fetch Delivery Zones Success',
  FETCH_DELIVERY_ZONES_FAILURE = '[BILLING] Fetch Delivery Zones Failure',
  FETCH_DELIVERY_FUEL_ZONES = '[BILLING] Fetch Delivery Fuel Zones',
  FETCH_DELIVERY_FUEL_ZONES_SUCCESS = '[BILLING] Fetch Delivery Fuel Zones Success',
  FETCH_DELIVERY_FUEL_ZONES_FAILURE = '[BILLING] Fetch Delivery Fuel Zones Failure',
  FETCH_ZONE_TO_ZONE = '[BILLING] Fetch Zone to Zone',
  FETCH_ZONE_TO_ZONE_SUCCESS = '[BILLING] Fetch Zone to Zone Success',
  FETCH_ZONE_TO_ZONE_FAILURE = '[BILLING] Fetch Zone to Zone Failure',
  FETCH_FUEL_ZONE_TO_FUEL_ZONE = '[BILLING] Fetch Fuel Zone to Fuel Zone',
  FETCH_FUEL_ZONE_TO_FUEL_ZONE_SUCCESS = '[BILLING] Fetch Fuel Zone to Fuel Zone Success',
  FETCH_FUEL_ZONE_TO_FUEL_ZONE_FAILURE = '[BILLING] Fetch Fuel Zone to Fuel Zone Failure',
  EXPORT_INVOICE_TRANSACTIONS = "[BILLING] Export invoice transactions",
  EXPORT_INVOICE_TRANSACTIONS_SUCCESS = "[BILLING] Export invoice transactions Success",
  EXPORT_INVOICE_TRANSACTIONS_FAILURE = "[BILLING] Export invoice transactions Failure",
}

export interface IFetchTariffRatesPayload {
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
  tspId?: number;
  tariffRateID?: number;
  chargeType?: string;
  dateEffective?: string;
  dateExpire?: string;
  status?: string;
}

export interface IFetchTariffRateDetailsPayload {
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: Array<SortDescriptor>;
  tariffRateID?: number;
  tspId?: number;
  rateDescription?: string;
  internalChargeTypeDescription?: string;
  internalChargeTypeAbbreviation?: string;
  rateSchedule?: string;
  dateEffective?: string;
  dateExpire?: string;
  chargeBasis?: string;
  rateType?: string;
  priceType?: string;
  chargePeriod?: string;
  rateComponent?: string;
  rateZoneDependency?: string;
  seasonal?: string;
  discountable?: string;
  userId?: string;
  tspChargeTypeId?: string;
  status?: string;
}

export class AddMasterChargeType implements Action {
  public readonly type = EBillingActions.ADD_MASTER_CHARGE_TYPE;

  constructor(public payload: MasterChargeType) {}
}

export class AddMasterChargeTypeError implements Action {
  public readonly type = EBillingActions.ADD_MASTER_CHARGE_TYPE_ERROR;

  constructor(public error: MasterChargeType) {}
}

export class AddMasterChargeTypeSuccess implements Action {
  public readonly type = EBillingActions.ADD_MASTER_CHARGE_TYPE_SUCCESS;

  constructor(public payload: MasterChargeType) {}
}

export class GetChargeTypes implements Action {
  public readonly type = EBillingActions.GET_CHARGE_TYPES;

  constructor(
    public payload?: {
      pageSize?: number;
      pageNumber?: number;
      sortDescriptors?: SortDescriptor[];
      tspId?: number;
      chargeType?: string;
      rateSchedule?: string;
      intChargeTypeAbbr?: string;
      intChargeTypeDesc?: string;
      chargeTypeDesc?: string;
      masterDateEffective?: string;
      masterDateExpire?: string;
      journalEntryTypeId?: string;
      naesbChargeTypeAbbr?: string;
      ignorePipeline: boolean;
    }
  ) {}
}

export class GetChargeTypesError implements Action {
  public readonly type = EBillingActions.GET_CHARGE_TYPES_ERROR;

  constructor(public error: Error) {}
}

export class GetChargeTypesSuccess implements Action {
  public readonly type = EBillingActions.GET_CHARGE_TYPES_SUCCESS;

  constructor(
    public payload: {
      chargeTypes: ChargeTypeCollection;
    }
  ) {}
}

export class FetchChargeTypeById implements Action {
  public readonly type = EBillingActions.FETCH_CHARGE_TYPES_BY_ID;

  constructor(public chargeTypeId: number) {}
}

export class FetchChargeTypeByIdSuccess implements Action {
  public readonly type = EBillingActions.FETCH_CHARGE_TYPES_BY_ID_SUCCESS;

  constructor(
    public payload: {
      chargeType: MasterChargeTypeDetail;
    }
  ) {}
}

export class FetchChargeTypeByIdFailure implements Action {
  public readonly type = EBillingActions.FETCH_CHARGE_TYPES_BY_ID_FAILURE;

  constructor(public error: Error) {}
}

export class FetchMultipleChargeTypesById implements Action {
  public readonly type = EBillingActions.FETCH_MULTIPLE_CHARGE_TYPES_BY_ID;

  constructor(public chargeTypeIds: Array<number>) {}
}

export class FetchMultipleChargeTypesByIdSuccess implements Action {
  public readonly type = EBillingActions.FETCH_MULTIPLE_CHARGE_TYPES_BY_ID_SUCCESS;

  constructor(
    public payload: {
      chargeTypes: Array<MasterChargeTypeDetail>;
    }
  ) {}
}

export class FetchMultipleChargeTypesByIdFailure implements Action {
  public readonly type = EBillingActions.FETCH_MULTIPLE_CHARGE_TYPES_BY_ID_FAILURE;

  constructor(public error: Error) {}
}

export class GetChargeTypesDictionary implements Action {
  public readonly type = EBillingActions.GET_CHARGE_TYPES_DICTIONARY;
}

export class GetChargeTypesDictionaryError implements Action {
  public readonly type = EBillingActions.GET_CHARGE_TYPES_DICTIONARY_ERROR;

  constructor(public error: Error) {}
}

export class GetChargeTypesDictionarySuccess implements Action {
  public readonly type = EBillingActions.GET_CHARGE_TYPES_DICTIONARY_SUCCESS;

  constructor(public payload: ChargeTypeDropdownDictionary) {}
}

export class FetchTariffRates implements Action {
  public readonly type = EBillingActions.FETCH_TARIFF_RATES;

  constructor(public payload?: IFetchTariffRatesPayload) {}
}

export class FetchTariffRatesSuccess implements Action {
  public readonly type = EBillingActions.FETCH_TARIFF_RATES_SUCCESS;

  constructor(
    public payload: {
      tariffRates: Array<TariffRateSummary>;
      totalTariffRateCount: number;
    }
  ) {}
}

export class FetchTariffRatesFailure implements Action {
  public readonly type = EBillingActions.FETCH_TARIFF_RATES_FAILURE;

  constructor(public error: Error) {}
}

export class FetchTariffRateById implements Action {
  public readonly type = EBillingActions.FETCH_TARIFF_RATE_BY_ID;

  constructor(public tariffRateId: number) {}
}

export class FetchTariffRateByIdFailure implements Action {
  public readonly type = EBillingActions.FETCH_TARIFF_RATE_BY_ID_FAILURE;

  constructor(public error: Error) {}
}

export class FetchTariffRateByIdSuccess implements Action {
  public readonly type = EBillingActions.FETCH_TARIFF_RATE_BY_ID_SUCCESS;

  constructor(public tariffRate: TariffRate) {}
}

export class GetTariffRateDictionary implements Action {
  public readonly type = EBillingActions.GET_TARIFF_RATE_DICTIONARY;
}

export class GetTariffRateDictionaryError implements Action {
  public readonly type = EBillingActions.GET_TARIFF_RATE_DICTIONARY_ERROR;

  constructor(public error: Error) {}
}

export class GetTariffRateDictionarySuccess implements Action {
  public readonly type = EBillingActions.GET_TARIFF_RATE_DICTIONARY_SUCCESS;

  constructor(public payload: TariffRateDropdownDictionary) {}
}

export class ResetMasterChargeType implements Action {
  public readonly type = EBillingActions.RESET_MASTER_CHARGE_TYPE;
}

export class ResetMasterChargeTypeError implements Action {
  public readonly type = EBillingActions.RESET_MASTER_CHARGE_TYPE_ERROR;
}

export class ResetMasterChargeTypeSuccess implements Action {
  public readonly type = EBillingActions.RESET_MASTER_CHARGE_TYPE_SUCCESS;
}

export class ResetMasterChargeTypeView implements Action {
  public readonly type = EBillingActions.RESET_MASTER_CHARGE_TYPE_VIEW;
}

export class FetchInvoiceById implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_BY_ID;

  constructor(public invoiceId: number) {}
}

export class FetchInvoiceByIdFailure implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_BY_ID_FAILURE;

  constructor(public error: Error) {}
}

export class FetchInvoiceByIdSuccess implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_BY_ID_SUCCESS;

  constructor(public payload: Invoice) {}
}

export class FetchInvoiceFilters implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_FILTERS;

  constructor(public payload: { tspId?: number }) {}
}

export class FetchInvoiceFiltersSuccess implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_FILTERS_SUCCESS;

  constructor(public payload: { invoiceFilterCollection: InvoiceFilterCollection }) {}
}

export class FetchInvoiceFiltersFailure implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_FILTERS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchInvoices implements Action {
  public readonly type = EBillingActions.FETCH_INVOICES;

  constructor(
    public payload?: {
      pageSize?: number;
      pageNumber?: number;
      sortDescriptors?: Array<SortDescriptor>;
      tspId?: number;
      accountingPeriod?: string;
      serviceRequestor?: string;
      accountingRep?: string;
      agentName?: string;
    }
  ) {}
}

export class FetchInvoicesSuccess implements Action {
  public readonly type = EBillingActions.FETCH_INVOICES_SUCCESS;

  constructor(
    public payload: {
      invoices: Array<InvoiceSummary>;
      totalInvoiceCount: number;
    }
  ) {}
}

export class FetchInvoicesFailure implements Action {
  public readonly type = EBillingActions.FETCH_INVOICES_FAILURE;

  constructor(public error: Error) {}
}

export class FetchInvoiceExceptions implements Action {
  public readonly type = EBillingActions.FETCH_INVOICES_EXCEPTIONS;

  constructor(
    public payload: {
      pageSize?: number;
      pageNumber?: number;
      sortDescriptors?: Array<SortDescriptor>;
      tspId?: number;
      accountingPeriod?: string;
      exceptionType?: InvoiceExceptionType;
    }
  ) {}
}

export class FetchInvoiceExceptionsSuccess implements Action {
  public readonly type = EBillingActions.FETCH_INVOICES_EXCEPTIONS_SUCCESS;

  constructor(
    public payload: {
      invoiceErrors: Array<InvoiceError>;
      total: number;
    }
  ) {}
}

export class FetchInvoiceExceptionsFailure implements Action {
  public readonly type = EBillingActions.FETCH_INVOICES_EXCEPTIONS_FAILURE;

  constructor(public error: Error) {}
}

export class SearchChargeTypes implements Action {
  public readonly type = EBillingActions.SEARCH_CHARGE_TYPES;

  constructor(
    public payload?: {
      chargeType: string;
      tspId?: number;
      sortBy?: string[];
    }
  ) {}
}

export class SearchChargeTypesSuccess implements Action {
  public readonly type = EBillingActions.SEARCH_CHARGE_TYPES_SUCCESS;

  constructor(
    public payload: {
      chargeTypes: Array<ChargeTypeSummary>;
    }
  ) {}
}

export class SearchChargeTypesFailure implements Action {
  public readonly type = EBillingActions.SEARCH_CHARGE_TYPES_FAILURE;

  constructor(public error: Error) {}
}

export class SearchDisqualificationFlowChargeTypes implements Action {
  public readonly type = EBillingActions.SEARCH_DISQUALIFICATION_FLOW_CHARGE_TYPES;

  constructor(
    public payload?: {
      chargeType: string;
      tspId?: number;
      sortBy?: string[];
    }
  ) {}
}

export class SearchDisqualificationFlowChargeTypesSuccess implements Action {
  public readonly type = EBillingActions.SEARCH_DISQUALIFICATION_FLOW_CHARGE_TYPES_SUCCESS;

  constructor(
    public payload: {
      chargeTypes: Array<MasterChargeType>;
    }
  ) {}
}

export class SearchSegmentationTotalsChargeTypes implements Action {
  public readonly type = EBillingActions.SEARCH_SEGMENTATION_TOTALS_CHARGE_TYPES;

  constructor(
    public payload?: {
      chargeType: string;
      tspId?: number;
      sortBy?: string[];
    }
  ) {}
}

export class SearchSegmentationTotalsChargeTypesSuccess implements Action {
  public readonly type = EBillingActions.SEARCH_SEGMENTATION_TOTALS_CHARGE_TYPES_SUCCESS;

  constructor(
    public payload: {
      chargeTypes: Array<MasterChargeType>;
    }
  ) {}
}

export class AddTariffRate implements Action {
  public readonly type = EBillingActions.ADD_TARIFF_RATE;

  constructor(public payload: TariffRate) {}
}

export class AddTariffRateFailure implements Action {
  public readonly type = EBillingActions.ADD_TARIFF_RATE_FAILURE;

  constructor(public error: Error) {}
}

export class AddTariffRateSuccess implements Action {
  public readonly type = EBillingActions.ADD_TARIFF_RATE_SUCCESS;

  constructor(public payload: TariffRate) {}
}

export class ResetTariffRate implements Action {
  public readonly type = EBillingActions.RESET_TARIFF_RATE;
}

export class UpdateMasterChargeType implements Action {
  public readonly type = EBillingActions.UPDATE_MASTER_CHARGE_TYPE;

  constructor(public payload: MasterChargeType, public id: number) {}
}

export class UpdateMasterChargeTypeSuccess implements Action {
  public readonly type = EBillingActions.UPDATE_MASTER_CHARGE_TYPE_SUCCESS;

  constructor(public payload: MasterChargeType) {}
}

export class UpdateMasterChargeTypeFailure implements Action {
  public readonly type = EBillingActions.UPDATE_MASTER_CHARGE_TYPE_FAILURE;

  constructor(public error: MasterChargeType) {}
}

export class FetchInvoiceTransactionDetails implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_TRANSACTION_DETAILS;

  constructor(
    public payload?: {
      view?: string;
      accountingPeriod?: string;
      productionPeriod?: string;
      pageSize?: number;
      pageNumber?: number;
      beginTrans?: string;
      endTrans?: string;
      sortDescriptors?: Array<SortDescriptor>;
      tspId?: number;
      serviceRequester?: string;
      agentName?: string;
      rateSchedule?: string;
      svcReqK?: string;
      intChrgType?: string;
      tt?: string;
      chargeIndicator?: string;
      chargeType?: string;
      rrCode?: string;
      priceType?: string;
      rateType?: string;
      recLoc?: string;
      recLocName?: string;
      recZone?: string;
      delLoc?: string;
      delLocName?: string;
      delZone?: string;
      chargeTypeOperator?: string;
      chargeTypeOne?: number;
      chargeTypeTwo?: number;
      invoiceId?: string;
      accountId?: string;
      accountTypeId?: string;
      miscNotesChargeTypeDescriptions?: string;
    }
  ) {}
}

export class FetchInvoiceTransactionDetailsSuccess implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_TRANSACTION_DETAILS_SUCCESS;

  constructor(
    public payload: {
      invoiceDetails: InvoiceDetailCollection;
    }
  ) {}
}

export class FetchInvoiceTransactionDetailsFailure implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_TRANSACTION_DETAILS_FAILURE;

  constructor(public error: Error) {}
}

export class FetchInvoiceTransactionDetailsTotal implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_TRANSACTION_DETAILS_TOTAL;

  constructor(
    public payload?: {
      view?: string;
      accountingPeriod?: string;
      serviceRequester?: string;
    }
  ) {}
}

export class ClearInvoiceTransactionDetailsTotal implements Action {
  public readonly type = EBillingActions.CLEAR_INVOICE_TRANSACTION_DETAILS_TOTAL;

  constructor() {}
}

export class FetchInvoiceTransactionDetailsTotalSuccess implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_TRANSACTION_DETAILS_TOTAL_SUCCESS;

  constructor(public payload: InvoiceDetailTotalCollection) {}
}

export class FetchInvoiceTransactionDetailsTotalFailure implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_TRANSACTION_DETAILS_TOTAL_FAILURE;

  constructor(public error: Error) {}
}

export class ClearInvoiceTransactionDetails implements Action {
  public readonly type = EBillingActions.CLEAR_INVOICE_TRANSACTION_DETAILS;
}

export class UpdateTariffRate implements Action {
  public readonly type = EBillingActions.UPDATE_TARIFF_RATE;

  constructor(public payload: TariffRate, public tariffRateId: number) {}
}

export class UpdateTariffRateFailure implements Action {
  public readonly type = EBillingActions.UPDATE_TARIFF_RATE_FAILURE;

  constructor(public error: Error) {}
}

export class UpdateTariffRateSuccess implements Action {
  public readonly type = EBillingActions.UPDATE_TARIFF_RATE_SUCCESS;

  constructor(public payload: TariffRate) {}
}

export class FetchChargeTypesDetails implements Action {
  public readonly type = EBillingActions.FETCH_CHARGE_TYPES_DETAILS;

  constructor(
    public payload?: {
      pageSize?: number;
      pageNumber?: number;
      sortDescriptors?: SortDescriptor[];
      tspId?: number;
      chargeTypeDateEffective?: string;
      chargeTypeDateExpire?: string;
      chargeBasis?: string;
      transactionType?: string;
      pipelineSystem?: string;
      rateSchedule?: string;
      internalAbbreviation?: string;
      internalDescription?: string;
      chargeTypeDescription?: string;
      journalEntryTypeID?: string;
      ignorePipeline?: boolean;
    }
  ) {}
}

export class FetchChargeTypesDetailsFailure implements Action {
  public readonly type = EBillingActions.FETCH_CHARGE_TYPES_DETAILS_FAILURE;

  constructor(public error: Error) {}
}

export class FetchChargeTypesDetailsSuccess implements Action {
  public readonly type = EBillingActions.FETCH_CHARGE_TYPES_DETAILS_SUCCESS;

  constructor(public payload: ChargeTypeDetailCollection) {}
}

export class FetchExportChargeTypesDetails implements Action {
  public readonly type = EBillingActions.FETCH_EXPORT_CHARGE_TYPES_DETAILS;

  constructor(
    public payload?: {
      pageSize?: number;
      pageNumber?: number;
      sortDescriptors?: SortDescriptor[];
      tspId?: number;
      chargeTypeDateEffective?: string;
      chargeTypeDateExpire?: string;
      chargeBasis?: string;
      transactionType?: string;
      pipelineSystem?: string;
      rateSchedule?: string;
      internalAbbreviation?: string;
      internalDescription?: string;
      chargeTypeDescription?: string;
      journalEntryTypeID?: string;
      ignorePipeline?: boolean;
    }
  ) {}
}

export class ClearExportChargeTypesDetails implements Action {
  public readonly type = EBillingActions.CLEAR_EXPORT_CHARGE_TYPES_DETAILS;

  constructor() {}
}

export class FetchExportChargeTypesDetailsSuccess implements Action {
  public readonly type = EBillingActions.FETCH_EXPORT_CHARGE_TYPES_DETAILS_SUCCESS;

  constructor(public payload: ChargeTypeDetailCollection) {}
}

export class FetchExportChargeTypesDetailsFailure implements Action {
  public readonly type = EBillingActions.FETCH_EXPORT_CHARGE_TYPES_DETAILS_FAILURE;

  constructor(public error: Error) {}
}

export class FetchEarliestOpenAccountingPeriod implements Action {
  public readonly type = EBillingActions.FETCH_EARLIEST_OPEN_ACCOUNTING_PERIOD;

  constructor(
    public payload?: {
      tspId?: number;
    }
  ) {}
}

export class FetchEarliestOpenAccountingPeriodSuccess implements Action {
  public readonly type = EBillingActions.FETCH_EARLIEST_OPEN_ACCOUNTING_PERIOD_SUCCESS;

  constructor(public payload: AccountingPeriod) {}
}

export class FetchEarliestOpenAccountingPeriodFailure implements Action {
  public readonly type = EBillingActions.FETCH_EARLIEST_OPEN_ACCOUNTING_PERIOD_FAILURE;

  constructor(public error: Error) {}
}

export class FetchTariffRateDetails implements Action {
  public readonly type = EBillingActions.FETCH_TARIFF_RATE_DETAILS;

  constructor(public payload?: IFetchTariffRateDetailsPayload) {}
}

export class FetchTariffRateDetailsSuccess implements Action {
  public readonly type = EBillingActions.FETCH_TARIFF_RATE_DETAILS_SUCCESS;

  constructor(
    public payload: {
      tarrifRateDetails: TariffRateDetailCollection;
    }
  ) {}
}

export class FetchTariffRateDetailsFailure implements Action {
  public readonly type = EBillingActions.FETCH_TARIFF_RATE_DETAILS_FAILURE;

  constructor(public error: Error) {}
}

export class ClearExportInvoiceDetails implements Action {
  public readonly type = EBillingActions.CLEAR_EXPORT_INVOICE_DETAILS;

  constructor() {}
}

export class FetchExportInvoiceExceptions implements Action {
  public readonly type = EBillingActions.FETCH_EXPORT_INVOICE_EXCEPTIONS;

  constructor(
    public payload?: {
      pageSize?: number;
      pageNumber?: number;
      tspId?: number;
      accountingPeriod?: string;
      exceptionType?: InvoiceExceptionType;
    }
  ) {}
}

export class ClearExportInvoiceExceptions implements Action {
  public readonly type = EBillingActions.CLEAR_EXPORT_INVOICE_EXCEPTIONS;

  constructor() {}
}

export class FetchExportInvoiceExceptionsSuccess implements Action {
  public readonly type = EBillingActions.FETCH_EXPORT_INVOICE_EXCEPTIONS_SUCCESS;

  constructor(
    public payload: {
      invoiceExceptions: Array<InvoiceError>;
    }
  ) {}
}

export class FetchExportInvoiceExceptionsFailure implements Action {
  public readonly type = EBillingActions.FETCH_EXPORT_INVOICE_EXCEPTIONS_FAILURE;

  constructor(public error: Error) {}
}

export class FetchExportTariffRateDetails implements Action {
  public readonly type = EBillingActions.FETCH_EXPORT_TARIFF_RATE_DETAILS;

  constructor(
    public payload?: IFetchTariffRateDetailsPayload) {}
}

export class FetchExportTariffRateDetailsSuccess implements Action {
  public readonly type = EBillingActions.FETCH_EXPORT_TARIFF_RATE_DETAILS_SUCCESS;

  constructor(
    public payload: {
      exportTarrifRateDetails: TariffRateDetailCollection;
    }
  ) {}
}

export class FetchExportTariffRateDetailsFailure implements Action {
  public readonly type = EBillingActions.FETCH_EXPORT_TARIFF_RATE_DETAILS_FAILURE;

  constructor(public error: Error) {}
}

export class ClearExportTariffRateDetails implements Action {
  public readonly type = EBillingActions.CLEAR_EXPORT_TARIFF_RATE_DETAILS;

  constructor() {}
}

export class FetchTspChargeTypeById implements Action {
  public readonly type = EBillingActions.FETCH_TSP_CHARGE_TYPE;

  constructor(public tspChargeTypeId: number) {}
}

export class FetchTspChargeTypeByIdSuccess implements Action {
  public readonly type = EBillingActions.FETCH_TSP_CHARGE_TYPE_SUCCESS;

  constructor(
    public payload: {
      tspChargeType: TspChargeTypeDetail;
    }
  ) {}
}

export class FetchTspChargeTypeByIdFailure implements Action {
  public readonly type = EBillingActions.FETCH_TSP_CHARGE_TYPE_FAILURE;

  constructor(public error: Error) {}
}

export class FetchMultipleTspChargeTypesById implements Action {
  public readonly type = EBillingActions.FETCH_MULTIPLE_TSP_CHARGE_TYPES_BY_ID;

  constructor(public tspChargeTypeIds: Array<number>) {}
}

export class FetchMultipleTspChargeTypesByIdSuccess implements Action {
  public readonly type = EBillingActions.FETCH_MULTIPLE_TSP_CHARGE_TYPES_BY_ID_SUCCESS;

  constructor(
    public payload: {
      tspChargeTypes: Array<TspChargeTypeDetail>;
    }
  ) {}
}

export class FetchMultipleTspChargeTypesByIdFailure implements Action {
  public readonly type = EBillingActions.FETCH_MULTIPLE_TSP_CHARGE_TYPES_BY_ID_FAILURE;

  constructor(public error: Error) {}
}

export class FetchTspReservationChargeTypes implements Action {
  public readonly type = EBillingActions.FETCH_TSP_RESERVATION_CHARGE_TYPES;

  constructor(
    public payload?: {
      tspId?: number;
      category: string;
      searchPhrase?: string;
      sortBy?: Array<string>;
      pageSize?: number;
      pageNumber?: number;
    }
  ) {}
}

export class FetchTspReservationChargeTypesSuccess implements Action {
  public readonly type = EBillingActions.FETCH_TSP_RESERVATION_CHARGE_TYPES_SUCCESS;

  constructor(
    public payload: {
      tspReservationChargeTypes: TspChargeTypeCollection;
    }
  ) {}
}

export class FetchTspReservationChargeTypesFailure implements Action {
  public readonly type = EBillingActions.FETCH_TSP_RESERVATION_CHARGE_TYPES_FAILURE;

  constructor(public error: Error) {}
}

export class FetchInvoiceGroupsById implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_GROUPS_BY_ID;

  constructor(public entityId: number) {}
}

export class FetchInvoiceGroupsByIdSuccess implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_GROUPS_BY_ID_SUCCESS;

  constructor(public invoiceGroupCollection: InvoiceGroupCollection) {}
}

export class FetchInvoiceGroupsByIdFailure implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_GROUPS_BY_ID_FAILURE;

  constructor(public error: Error) {}
}

export class AddInvoiceGroup implements Action {
  public readonly type = EBillingActions.ADD_INVOICE_GROUP;

  constructor(public payload: InvoiceGroup) {}
}

export class AddInvoiceGroupFailure implements Action {
  public readonly type = EBillingActions.ADD_INVOICE_GROUP_FAILURE;

  constructor(public error: Error) {}
}

export class AddInvoiceGroupSuccess implements Action {
  public readonly type = EBillingActions.ADD_INVOICE_GROUP_SUCCESS;

  constructor(public payload: InvoiceGroup) {}
}

export class ResetInvoiceGroup implements Action {
  public readonly type = EBillingActions.RESET_INVOICE_GROUP;
}

export class FetchInvoiceGroupById implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_GROUP_BY_ID;

  constructor(public invoiceGroupId: number) {}
}

export class FetchInvoiceGroupByIdSuccess implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_GROUP_BY_ID_SUCCESS;

  constructor(public invoiceGroup: InvoiceGroup) {}
}

export class FetchInvoiceGroupByIdFailure implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_GROUP_BY_ID_FAILURE;

  constructor(public error: Error) {}
}

export class UpdateInvoiceGroup implements Action {
  public readonly type = EBillingActions.UPDATE_INVOICE_GROUP;

  constructor(public payload: InvoiceGroup, public invoiceGroupId: number) {}
}

export class UpdateInvoiceGroupFailure implements Action {
  public readonly type = EBillingActions.UPDATE_INVOICE_GROUP_FAILURE;

  constructor(public error: Error) {}
}

export class UpdateInvoiceGroupSuccess implements Action {
  public readonly type = EBillingActions.UPDATE_INVOICE_GROUP_SUCCESS;

  constructor(public payload: InvoiceGroup) {}
}

export class FetchAllOpenAccountingPeriods implements Action {
  public readonly type = EBillingActions.FETCH_ALL_OPEN_ACCOUNTING_PERIODS;

  constructor(
    public payload?: {
      tspId?: number;
    }
  ) {}
}

export class FetchAllOpenAccountingPeriodsSuccess implements Action {
  public readonly type = EBillingActions.FETCH_ALL_OPEN_ACCOUNTING_PERIODS_SUCCESS;

  constructor(public payload: AccountingPeriodCollection) {}
}

export class FetchAllOpenAccountingPeriodsFailure implements Action {
  public readonly type = EBillingActions.FETCH_ALL_OPEN_ACCOUNTING_PERIODS_FAILURE;

  constructor(public error: Error) {}
}

export class SetChargeTypesNavigationState implements Action {
  public readonly type = EBillingActions.SET_CHARGE_TYPES_NAVIGATION_STATE;

  constructor(public payload: ChargeTypesListNavigationState) {}
}

export class UpdateTariffRateWorkflow implements Action {
  public readonly type = EBillingActions.UPDATE_TARIFF_RATE_WORKFLOW;

  constructor(
    public payload: {
      tariffRateId: number;
      tariffRateVersionId: number;
      tariffRateVersionWorkflowAction: TariffRateVersionWorkflowAction;
    }
  ) {}
}

export class UpdateTariffRateWorkflowSuccess implements Action {
  public readonly type = EBillingActions.UPDATE_TARIFF_RATE_WORKFLOW_SUCCESS;

  constructor() {}
}

export class UpdateTariffRateWorkflowFailure implements Action {
  public readonly type = EBillingActions.UPDATE_TARIFF_RATE_WORKFLOW_FAILURE;

  constructor(public error: Error) {}
}

export class ClearTariffRateWorkflow implements Action {
  public readonly type = EBillingActions.CLEAR_TARIFF_RATE_WORKFLOW;

  constructor() {}
}

export class FetchIndexRate implements Action {
  public readonly type = EBillingActions.FETCH_INDEX_RATE;

  constructor(
    public payload: {
      rateId: number;
      productionPeriod?: string;
    }
  ) {}
}

export class FetchIndexRateFailure implements Action {
  public readonly type = EBillingActions.FETCH_INDEX_RATE_FAILURE;

  constructor(public error: Error) {}
}

export class FetchIndexRateSuccess implements Action {
  public readonly type = EBillingActions.FETCH_INDEX_RATE_SUCCESS;

  constructor(public payload: IndexRateDetail) {}
}

export class ClearIndexRate implements Action {
  public readonly type = EBillingActions.CLEAR_INDEX_RATE;

  constructor() {}
}

export class AddIndexRate implements Action {
  public readonly type = EBillingActions.ADD_INDEX_RATE;

  constructor(
    public payload: {
      rateId: number;
      indexRate: IndexRate;
      productionPeriod: string;
    }
  ) {}
}

export class AddIndexRateFailure implements Action {
  public readonly type = EBillingActions.ADD_INDEX_RATE_FAILURE;

  constructor(public error: Error) {}
}

export class AddIndexRateSuccess implements Action {
  public readonly type = EBillingActions.ADD_INDEX_RATE_SUCCESS;

  constructor(public payload: IndexRate) {}
}

export class FetchCalculatedPriceTypes implements Action {
  public readonly type = EBillingActions.FETCH_CALCULATED_PRICE_TYPES;

  constructor(
    public payload?: {
      tspId?: number;
    }
  ) {}
}

export class FetchCalculatedPriceTypesFailure implements Action {
  public readonly type = EBillingActions.FETCH_CALCULATED_PRICE_TYPES_FAILURE;

  constructor(public error: Error) {}
}

export class FetchCalculatedPriceTypesSuccess implements Action {
  public readonly type = EBillingActions.FETCH_CALCULATED_PRICE_TYPES_SUCCESS;

  constructor(public payload: CalculatedPriceTypeCollection) {}
}

export class FetchIndexRates extends RetryableAction implements Action {
  public readonly type = EBillingActions.FETCH_INDEX_RATES;

  constructor(
    public payload?: {
      tspId?: number;
      capacityReleaseFlag?: boolean;
    }
  ) {
    super(payload);
  }
}

export class FetchIndexRatesFailure implements Action {
  public readonly type = EBillingActions.FETCH_INDEX_RATES_FAILURE;

  constructor(public error: Error) {}
}

export class FetchIndexRatesSuccess implements Action {
  public readonly type = EBillingActions.FETCH_INDEX_RATES_SUCCESS;

  constructor(public payload: IndexRateSummaryCollection) {}
}

export interface FetchAccountsPayload {
  tspId?: number;
  pageSize?: number;
  pageNumber?: number;
  sortBy?: Array<string>;
  accountTypeId?: number;
  entityId?: number;
  entityIds?: string;
  accountIds?: string;
  viewBy?: string;
  searchPhrase?: string;
}

export class FetchAccounts implements Action {
  public readonly type = EBillingActions.FETCH_ACCOUNTS;

  constructor(public payload: FetchAccountsPayload) {}
}

export class FetchAccountsSuccess implements Action {
  public readonly type = EBillingActions.FETCH_ACCOUNTS_SUCCESS;

  constructor(public payload: AccountAllDetailCollection) {}
}

export class FetchAccountsFailure implements Action {
  public readonly type = EBillingActions.FETCH_ACCOUNTS_FAILURE;

  constructor(public error: Error) {}
}

export interface FetchInvoiceAttachmentsPayload {
  tspId: number;
  accountPeriod: IAccountPeriod;
  svcReqId?: number;
}

export class FetchInvoiceAttachments implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_ATTACHMENTS;

  constructor(public payload: FetchInvoiceAttachmentsPayload) {}
}

export class FetchInvoiceAttachmentsSuccess implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_ATTACHMENTS_SUCCESS;

  constructor(public payload: InvoiceAttachmentCollection) {}
}

export class FetchInvoiceAttachmentsFailure implements Action {
  public readonly type = EBillingActions.FETCH_INVOICE_ATTACHMENTS_FAILURE;

  constructor(public error: Error) {}
}

export class DeleteInvoiceAttachment implements Action {
  public readonly type = EBillingActions.DELETE_INVOICE_ATTACHMENT;

  constructor(public attachmentId: number) {}
}

export class DeleteInvoiceAttachmentSuccess implements Action {
  public readonly type = EBillingActions.DELETE_INVOICE_ATTACHMENT_SUCCESS;
}

export class DeleteInvoiceAttachmentFailure implements Action {
  public readonly type = EBillingActions.DELETE_INVOICE_ATTACHMENT_FAILURE;

  constructor(public error: Error) {}
}

export interface IUploadInvoiceAttachmentPayload {
  accountingPeriod: Date;
  description: string;
  file: File;
  svcReqId: number;
  svcReqName: string;
  invoiceGroupId?: number;
  tspId: number;
}

export class UploadInvoiceAttachment implements Action {
  public readonly type = EBillingActions.UPLOAD_INVOICE_ATTACHMENT;

  constructor(public payload: IUploadInvoiceAttachmentPayload) {}
}

export class UploadInvoiceAttachmentSuccess implements Action {
  public readonly type = EBillingActions.UPLOAD_INVOICE_ATTACHMENT_SUCCESS;
}

export class UploadInvoiceAttachmentFailure implements Action {
  public readonly type = EBillingActions.UPLOAD_INVOICE_ATTACHMENT_FAILURE;

  constructor(public error: Error) {}
}

export class DownloadInvoicePackage implements Action {
  public readonly type = EBillingActions.DOWNLOAD_INVOICE_PACKAGE;

  constructor(public invoiceId: number) {}
}

export class DownloadInvoicePackageSuccess implements Action {
  public readonly type = EBillingActions.DOWNLOAD_INVOICE_PACKAGE_SUCCESS;
}

export class DownloadInvoicePackageFailure implements Action {
  public readonly type = EBillingActions.DOWNLOAD_INVOICE_PACKAGE_FAILURE;

  constructor(public error: Error) {}
}

export class GetPalDailyBillingRate implements Action {
  public readonly type = EBillingActions.GET_PAL_DAILY_BILLING_RATE;

  constructor(public payload: { request: PalDailyRateRequest; contractId: number; rate: number }) {}
}

export class GetPalDailyBillingRateSuccess implements Action {
  public readonly type = EBillingActions.GET_PAL_DAILY_BILLING_RATE_SUCCESS;

  constructor(public payload: { contractId: number; rate: number }) {}
}

export class GetPalDailyBillingRateFailure implements Action {
  public readonly type = EBillingActions.GET_PAL_DAILY_BILLING_RATE_FAILURE;

  constructor(public error: Error, public contractId: number, public rate: number) {}
}

export class GetAccountingPeriods implements Action {
  public readonly type = EBillingActions.GET_ACCOUNTING_PERIODS;

  constructor(
    public payload?: {
      tspId?: number;
    }
  ) {}
}

export class GetAccountingPeriodsSuccess implements Action {
  public readonly type = EBillingActions.GET_ACCOUNTING_PERIODS_SUCCESS;

  constructor(public payload: AccountingPeriod[]) {}
}

export class GetAccountingPeriodsFailure implements Action {
  public readonly type = EBillingActions.GET_ACCOUNTING_PERIODS_FAILURE;

  constructor(public error: Error) {}
}

export class FetchCapRelIndexRates implements Action {
  public readonly type = EBillingActions.FETCH_CAP_REL_INDEX_RATES;

  constructor(
    public payload: {
      tspId: number;
    }
  ) {}
}

export class FetchCapRelIndexRatesFailure implements Action {
  public readonly type = EBillingActions.FETCH_CAP_REL_INDEX_RATES_FAILURE;

  constructor(public error: Error) {}
}

export class FetchCapRelIndexRatesSuccess implements Action {
  public readonly type = EBillingActions.FETCH_CAP_REL_INDEX_RATES_SUCCESS;

  constructor(public payload: IndexRateSummaryCollection) {}
}

export class FetchZoneToZone implements Action {
  public readonly type = EBillingActions.FETCH_ZONE_TO_ZONE;

  constructor(public tspId: number, public haulDirection?: string) {}
}

export class FetchZoneToZoneSuccess implements Action {
  public readonly type = EBillingActions.FETCH_ZONE_TO_ZONE_SUCCESS;

  constructor(public payload: Array<ZoneToZone>) {}
}

export class FetchZoneToZoneFailure implements Action {
  public readonly type = EBillingActions.FETCH_ZONE_TO_ZONE_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchFuelZoneToFuelZone implements Action {
  public readonly type = EBillingActions.FETCH_FUEL_ZONE_TO_FUEL_ZONE;

  constructor(public tspId: number, public haulDirection?: string) {}
}

export class FetchFuelZoneToFuelZoneSuccess implements Action {
  public readonly type = EBillingActions.FETCH_FUEL_ZONE_TO_FUEL_ZONE_SUCCESS;

  constructor(public payload: Array<ZoneToZone>) {}
}

export class FetchFuelZoneToFuelZoneFailure implements Action {
  public readonly type = EBillingActions.FETCH_FUEL_ZONE_TO_FUEL_ZONE_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchDeliveryZones implements Action {
  public readonly type = EBillingActions.FETCH_DELIVERY_ZONES;

  constructor(public tspId: number) {}
}

export class FetchDeliveryZonesSuccess implements Action {
  public readonly type = EBillingActions.FETCH_DELIVERY_ZONES_SUCCESS;

  constructor(public payload: Array<Zone>) {}
}

export class FetchDeliveryZonesFailure implements Action {
  public readonly type = EBillingActions.FETCH_DELIVERY_ZONES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchDeliveryFuelZones implements Action {
  public readonly type = EBillingActions.FETCH_DELIVERY_FUEL_ZONES;

  constructor(public tspId: number, public fuelZones?: boolean) {}
}

export class FetchDeliveryFuelZonesSuccess implements Action {
  public readonly type = EBillingActions.FETCH_DELIVERY_FUEL_ZONES_SUCCESS;

  constructor(public payload: Array<Zone>) {}
}

export class FetchDeliveryFuelZonesFailure implements Action {
  public readonly type = EBillingActions.FETCH_DELIVERY_FUEL_ZONES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ExportInvoiceTransactions implements Action {
  public readonly type = EBillingActions.EXPORT_INVOICE_TRANSACTIONS;

  constructor(
    public payload?: {
      view?: string;
      accountingPeriod?: string;
      productionPeriod?: string;
      pageSize?: number;
      pageNumber?: number;
      beginTrans?: string;
      endTrans?: string;
      sortDescriptors?: Array<SortDescriptor>;
      tspId?: number;
      invoiceId?: string;
      serviceRequester?: string;
      agentName?: string;
      rateSchedule?: string;
      svcReqK?: string;
      intChrgType?: string;
      tt?: string;
      chargeIndicator?: string;
      chargeType?: string;
      rrCode?: string;
      priceType?: string;
      rateType?: string;
      recLoc?: string;
      recLocName?: string;
      recZone?: string;
      delLoc?: string;
      delLocName?: string;
      delZone?: string;
      chargeTypeOperator?: string;
      chargeTypeOne?: number;
      chargeTypeTwo?: number;
      accountId?: string;
      accountTypeId?: string;
    }
  ) {}
}

export class ExportInvoiceTransactionsSuccess implements Action {
  public readonly type = EBillingActions.EXPORT_INVOICE_TRANSACTIONS_SUCCESS;

  constructor() {}
}

export class ExportInvoiceTransactionsFailure implements Action {
  public readonly type = EBillingActions.EXPORT_INVOICE_TRANSACTIONS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export type BillingActions =
  | AddIndexRate
  | AddIndexRateFailure
  | AddIndexRateSuccess
  | AddMasterChargeType
  | AddMasterChargeTypeError
  | AddMasterChargeTypeSuccess
  | AddTariffRate
  | AddTariffRateFailure
  | AddTariffRateSuccess
  | GetChargeTypes
  | GetChargeTypesError
  | GetChargeTypesSuccess
  | FetchInvoiceById
  | FetchInvoiceByIdSuccess
  | FetchInvoiceByIdFailure
  | FetchInvoices
  | FetchInvoicesSuccess
  | FetchInvoicesFailure
  | FetchInvoiceExceptions
  | FetchInvoiceExceptionsSuccess
  | FetchInvoiceExceptionsFailure
  | FetchTariffRateById
  | FetchTariffRateByIdFailure
  | FetchTariffRateByIdSuccess
  | FetchTariffRates
  | FetchTariffRatesFailure
  | FetchTariffRatesSuccess
  | GetChargeTypesDictionary
  | GetChargeTypesDictionaryError
  | GetChargeTypesDictionarySuccess
  | GetTariffRateDictionary
  | GetTariffRateDictionaryError
  | GetTariffRateDictionarySuccess
  | FetchChargeTypeById
  | FetchChargeTypeByIdSuccess
  | FetchChargeTypeByIdFailure
  | FetchMultipleChargeTypesById
  | FetchMultipleChargeTypesByIdSuccess
  | FetchMultipleChargeTypesByIdFailure
  | ResetMasterChargeType
  | ResetMasterChargeTypeError
  | ResetMasterChargeTypeSuccess
  | FetchInvoiceFilters
  | FetchInvoiceFiltersSuccess
  | FetchInvoiceFiltersFailure
  | ResetTariffRate
  | SearchSegmentationTotalsChargeTypes
  | SearchSegmentationTotalsChargeTypesSuccess
  | SearchDisqualificationFlowChargeTypes
  | SearchDisqualificationFlowChargeTypesSuccess
  | SearchChargeTypes
  | SearchChargeTypesSuccess
  | SearchChargeTypesFailure
  | UpdateMasterChargeType
  | UpdateMasterChargeTypeFailure
  | UpdateMasterChargeTypeSuccess
  | FetchInvoiceTransactionDetails
  | FetchInvoiceTransactionDetailsSuccess
  | FetchInvoiceTransactionDetailsFailure
  | ClearInvoiceTransactionDetails
  | UpdateTariffRate
  | UpdateTariffRateSuccess
  | UpdateTariffRateFailure
  | FetchChargeTypesDetails
  | FetchChargeTypesDetailsFailure
  | FetchChargeTypesDetailsSuccess
  | FetchExportChargeTypesDetails
  | FetchExportChargeTypesDetailsSuccess
  | FetchExportChargeTypesDetailsFailure
  | FetchEarliestOpenAccountingPeriod
  | FetchEarliestOpenAccountingPeriodFailure
  | FetchEarliestOpenAccountingPeriodSuccess
  | FetchTariffRateDetails
  | FetchTariffRateDetailsSuccess
  | FetchTariffRateDetailsFailure
  | ClearExportChargeTypesDetails
  | ClearExportInvoiceDetails
  | FetchInvoiceTransactionDetailsTotal
  | FetchInvoiceTransactionDetailsTotalFailure
  | FetchInvoiceTransactionDetailsTotalSuccess
  | ClearInvoiceTransactionDetailsTotal
  | FetchExportInvoiceExceptions
  | FetchExportInvoiceExceptionsFailure
  | FetchExportInvoiceExceptionsSuccess
  | ClearExportInvoiceExceptions
  | FetchExportTariffRateDetails
  | FetchExportTariffRateDetailsFailure
  | FetchExportTariffRateDetailsSuccess
  | ClearExportTariffRateDetails
  | FetchTspChargeTypeById
  | FetchTspChargeTypeByIdSuccess
  | FetchTspChargeTypeByIdFailure
  | FetchMultipleTspChargeTypesById
  | FetchMultipleTspChargeTypesByIdSuccess
  | FetchMultipleTspChargeTypesByIdFailure
  | FetchTspReservationChargeTypes
  | FetchTspReservationChargeTypesSuccess
  | FetchTspReservationChargeTypesFailure
  | FetchInvoiceGroupsById
  | FetchInvoiceGroupsByIdSuccess
  | FetchInvoiceGroupsByIdFailure
  | AddInvoiceGroup
  | AddInvoiceGroupSuccess
  | AddInvoiceGroupFailure
  | ResetInvoiceGroup
  | FetchInvoiceGroupById
  | FetchInvoiceGroupByIdSuccess
  | FetchInvoiceGroupByIdFailure
  | UpdateInvoiceGroup
  | UpdateInvoiceGroupFailure
  | UpdateInvoiceGroupSuccess
  | FetchAllOpenAccountingPeriods
  | FetchAllOpenAccountingPeriodsSuccess
  | FetchAllOpenAccountingPeriodsFailure
  | SetChargeTypesNavigationState
  | UpdateTariffRateWorkflow
  | UpdateTariffRateWorkflowFailure
  | UpdateTariffRateWorkflowSuccess
  | ClearTariffRateWorkflow
  | FetchIndexRate
  | FetchIndexRateFailure
  | FetchIndexRateSuccess
  | ClearIndexRate
  | FetchCalculatedPriceTypes
  | FetchCalculatedPriceTypesFailure
  | FetchCalculatedPriceTypesSuccess
  | FetchIndexRates
  | FetchIndexRatesFailure
  | FetchIndexRatesSuccess
  | ResetMasterChargeTypeView
  | FetchAccounts
  | FetchAccountsSuccess
  | FetchAccountsFailure
  | FetchInvoiceAttachments
  | FetchInvoiceAttachmentsSuccess
  | FetchInvoiceAttachmentsFailure
  | DeleteInvoiceAttachment
  | DeleteInvoiceAttachmentSuccess
  | DeleteInvoiceAttachmentFailure
  | UploadInvoiceAttachment
  | UploadInvoiceAttachmentSuccess
  | UploadInvoiceAttachmentFailure
  | DownloadInvoicePackage
  | DownloadInvoicePackageSuccess
  | DownloadInvoicePackageFailure
  | GetPalDailyBillingRate
  | GetPalDailyBillingRateSuccess
  | GetPalDailyBillingRateFailure
  | GetAccountingPeriods
  | GetAccountingPeriodsSuccess
  | GetAccountingPeriodsFailure
  | FetchCapRelIndexRates
  | FetchCapRelIndexRatesSuccess
  | FetchCapRelIndexRatesFailure
  | FetchDeliveryZones
  | FetchDeliveryZonesSuccess
  | FetchDeliveryZonesFailure
  | FetchDeliveryFuelZones
  | FetchDeliveryFuelZonesSuccess
  | FetchDeliveryFuelZonesFailure
  | FetchZoneToZone
  | FetchZoneToZoneSuccess
  | FetchZoneToZoneFailure
  | FetchFuelZoneToFuelZone
  | FetchFuelZoneToFuelZoneSuccess
  | FetchFuelZoneToFuelZoneFailure
  | ExportInvoiceTransactions
  | ExportInvoiceTransactionsSuccess
  | ExportInvoiceTransactionsFailure;
