import { Injectable } from '@angular/core';
import {
  AccountAllDetailCollection,
  AccountingPeriodCollection,
  BillingAccountAllService,
  BillingCalculatedPriceTypesService,
  BillingChargetypeDetailsService,
  BillingChargetypesDictionaryService,
  BillingChargetypeService,
  BillingIndexRateService,
  BillingIndexRatesService,
  BillingInvoiceGroupsService,
  BillingInvoicesAccountingPeriodService,
  BillingInvoicesAccountingPeriodsService,
  BillingInvoicesAllAccountingPeriodsService,
  BillingInvoicesAttachmentsService,
  BillingInvoicesDetailsService,
  BillingInvoicesDetailsTotalService,
  BillingInvoicesErrorsService,
  BillingInvoicesFilterValuesService,
  BillingInvoicesService,
  BillingPalDailyRateService,
  BillingTariffratesDictionaryService,
  BillingTariffRatesService,
  BillingTspChargetypeCategoryService,
  BillingTspChargetypeService,
  BillingZonesService,
  CalculatedPriceTypeCollection,
  CapRelIndexRatesService,
  ChargeTypeCollection,
  ChargeTypeDropdownDictionary,
  ChargeTypeSummary,
  IndexRateDetail,
  IndexRateSummaryCollection,
  Invoice,
  InvoiceAttachmentCollection,
  InvoiceCollection,
  InvoiceDetailCollection,
  InvoiceDetailTotalCollection,
  InvoiceErrorCollection,
  InvoiceFilterCollection,
  InvoiceGroup,
  InvoiceGroupCollection,
  MasterChargeType,
  TariffRate,
  TariffRateCollection,
  TariffRateDetailCollection,
  TariffRateDropdownDictionary,
  TspChargeTypeDetail,
  ZoneToZone,
} from '@gms/billing-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { convertAccountPeriodToDate } from 'app/modules/accounting/utils/journal-entries-utils';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, switchMap } from 'rxjs/operators';
import { dateUtils } from 'shared/utils/date.utils';
import {
  AddIndexRate,
  AddIndexRateFailure,
  AddIndexRateSuccess,
  AddInvoiceGroup,
  AddInvoiceGroupFailure,
  AddInvoiceGroupSuccess,
  AddMasterChargeType,
  AddMasterChargeTypeError,
  AddMasterChargeTypeSuccess,
  AddTariffRate,
  AddTariffRateFailure,
  AddTariffRateSuccess,
  DeleteInvoiceAttachment,
  DeleteInvoiceAttachmentFailure,
  DeleteInvoiceAttachmentSuccess,
  DownloadInvoicePackage,
  DownloadInvoicePackageFailure,
  DownloadInvoicePackageSuccess,
  EBillingActions,
  FetchAccounts,
  FetchAccountsFailure,
  FetchAccountsPayload,
  FetchAccountsSuccess,
  FetchAllOpenAccountingPeriods,
  FetchAllOpenAccountingPeriodsFailure,
  FetchAllOpenAccountingPeriodsSuccess,
  FetchCalculatedPriceTypes,
  FetchCalculatedPriceTypesFailure,
  FetchCalculatedPriceTypesSuccess,
  FetchCapRelIndexRates,
  FetchCapRelIndexRatesFailure,
  FetchCapRelIndexRatesSuccess,
  FetchChargeTypeById,
  FetchChargeTypeByIdFailure,
  FetchChargeTypeByIdSuccess,
  FetchChargeTypesDetails,
  FetchChargeTypesDetailsFailure,
  FetchChargeTypesDetailsSuccess,
  FetchDeliveryFuelZones,
  FetchDeliveryFuelZonesFailure,
  FetchDeliveryFuelZonesSuccess,
  FetchDeliveryZones,
  FetchDeliveryZonesFailure,
  FetchDeliveryZonesSuccess,
  FetchEarliestOpenAccountingPeriod,
  FetchEarliestOpenAccountingPeriodFailure,
  FetchEarliestOpenAccountingPeriodSuccess,
  FetchExportChargeTypesDetails,
  FetchExportChargeTypesDetailsFailure,
  FetchExportChargeTypesDetailsSuccess,
  FetchExportInvoiceExceptions,
  FetchExportInvoiceExceptionsFailure,
  FetchExportInvoiceExceptionsSuccess,
  FetchExportTariffRateDetails,
  FetchExportTariffRateDetailsFailure,
  FetchExportTariffRateDetailsSuccess,
  FetchFuelZoneToFuelZone,
  FetchFuelZoneToFuelZoneFailure,
  FetchFuelZoneToFuelZoneSuccess,
  FetchIndexRate,
  FetchIndexRateFailure,
  FetchIndexRates,
  FetchIndexRatesFailure,
  FetchIndexRatesSuccess,
  FetchIndexRateSuccess,
  FetchInvoiceAttachments,
  FetchInvoiceAttachmentsFailure,
  FetchInvoiceAttachmentsPayload,
  FetchInvoiceAttachmentsSuccess,
  FetchInvoiceById,
  FetchInvoiceByIdFailure,
  FetchInvoiceByIdSuccess,
  FetchInvoiceExceptions,
  FetchInvoiceExceptionsFailure,
  FetchInvoiceExceptionsSuccess,
  FetchInvoiceFilters,
  FetchInvoiceFiltersFailure,
  FetchInvoiceFiltersSuccess,
  FetchInvoiceGroupById,
  FetchInvoiceGroupByIdFailure,
  FetchInvoiceGroupByIdSuccess,
  FetchInvoiceGroupsById,
  FetchInvoiceGroupsByIdFailure,
  FetchInvoiceGroupsByIdSuccess,
  FetchInvoices,
  FetchInvoicesFailure,
  FetchInvoicesSuccess,
  FetchInvoiceTransactionDetails,
  FetchInvoiceTransactionDetailsFailure,
  FetchInvoiceTransactionDetailsSuccess,
  FetchInvoiceTransactionDetailsTotal,
  FetchInvoiceTransactionDetailsTotalSuccess,
  FetchMultipleChargeTypesById,
  FetchMultipleChargeTypesByIdFailure,
  FetchMultipleChargeTypesByIdSuccess,
  FetchMultipleTspChargeTypesById,
  FetchMultipleTspChargeTypesByIdFailure,
  FetchMultipleTspChargeTypesByIdSuccess,
  FetchTariffRateById,
  FetchTariffRateByIdFailure,
  FetchTariffRateByIdSuccess,
  FetchTariffRateDetails,
  FetchTariffRateDetailsFailure,
  FetchTariffRateDetailsSuccess,
  FetchTariffRates,
  FetchTariffRatesFailure,
  FetchTariffRatesSuccess,
  FetchTspChargeTypeById,
  FetchTspChargeTypeByIdFailure,
  FetchTspChargeTypeByIdSuccess,
  FetchTspReservationChargeTypes,
  FetchTspReservationChargeTypesFailure,
  FetchTspReservationChargeTypesSuccess,
  FetchZoneToZone,
  FetchZoneToZoneFailure,
  FetchZoneToZoneSuccess,
  GetAccountingPeriods,
  GetAccountingPeriodsFailure,
  GetAccountingPeriodsSuccess,
  GetChargeTypes,
  GetChargeTypesDictionary,
  GetChargeTypesDictionaryError,
  GetChargeTypesDictionarySuccess,
  GetChargeTypesError,
  GetChargeTypesSuccess,
  GetPalDailyBillingRate,
  GetPalDailyBillingRateFailure,
  GetPalDailyBillingRateSuccess,
  GetTariffRateDictionary,
  GetTariffRateDictionaryError,
  GetTariffRateDictionarySuccess,
  IUploadInvoiceAttachmentPayload,
  SearchChargeTypes,
  SearchChargeTypesFailure,
  SearchChargeTypesSuccess,
  SearchDisqualificationFlowChargeTypes,
  SearchDisqualificationFlowChargeTypesSuccess,
  SearchSegmentationTotalsChargeTypes,
  SearchSegmentationTotalsChargeTypesSuccess,
  UpdateInvoiceGroup,
  UpdateInvoiceGroupFailure,
  UpdateInvoiceGroupSuccess,
  UpdateMasterChargeType,
  UpdateMasterChargeTypeFailure,
  UpdateMasterChargeTypeSuccess,
  UpdateTariffRate,
  UpdateTariffRateFailure,
  UpdateTariffRateSuccess,
  UpdateTariffRateWorkflow,
  UpdateTariffRateWorkflowFailure,
  UpdateTariffRateWorkflowSuccess,
  UploadInvoiceAttachment,
  UploadInvoiceAttachmentFailure,
  UploadInvoiceAttachmentSuccess,
  ExportInvoiceTransactions,
  ExportInvoiceTransactionsSuccess,
  ExportInvoiceTransactionsFailure,
} from './billing.actions';

@Injectable({
  providedIn: 'root',
})
export class BillingEffects {
  DATE_KEYS = ['dateEffective', 'dateExpire'];

  constructor(
    private _actions$: Actions,
    private _billingAccountAllService: BillingAccountAllService,
    private _billingInvoicesFilterValuesService: BillingInvoicesFilterValuesService,
    private _billingService: BillingChargetypeService,
    private _billingInvoicesErrorsService: BillingInvoicesErrorsService,
    private _billingChargeTypesDetailsService: BillingChargetypeDetailsService,
    private _billingTariffRateService: BillingTariffRatesService,
    private _chargeTypeDictionaryService: BillingChargetypesDictionaryService,
    private _tariffRateDictionaryService: BillingTariffratesDictionaryService,
    private _invoiceService: BillingInvoicesService,
    private _billingInvoiceDetailsService: BillingInvoicesDetailsService,
    private _billingInvoiceDetailsTotalService: BillingInvoicesDetailsTotalService,
    private _billingInvoicesAccountingPeriodService: BillingInvoicesAccountingPeriodService,
    private _billingIndexRateService: BillingIndexRateService,
    private _billingCalculatedPriceTypesService: BillingCalculatedPriceTypesService,
    private _billingTspChargeTypeService: BillingTspChargetypeService,
    private _billingTspChargeTypeReservationService: BillingTspChargetypeCategoryService,
    private _billingInvoiceGroupService: BillingInvoiceGroupsService,
    private _billingInvoicesAccountingPeriods: BillingInvoicesAccountingPeriodsService,
    private _billingIndexRatesService: BillingIndexRatesService,
    private _billingInvoicesAttachmentsService: BillingInvoicesAttachmentsService,
    private _billingPalDailyRateService: BillingPalDailyRateService,
    private _billingInvoicesAllAccountingPeriodsService: BillingInvoicesAllAccountingPeriodsService,
    private _capRelIndexRatesService: CapRelIndexRatesService,
    private _billingZoneService: BillingZonesService
  ) {}

  addChargeType$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddMasterChargeType>(EBillingActions.ADD_MASTER_CHARGE_TYPE),
      map((action: AddMasterChargeType) => action),
      switchMap(action =>
        this._billingService.addChargeType(action.payload).pipe(
          map(chargeType => {
            const tempChargeType = JSON.parse(JSON.stringify(chargeType));

            this.DATE_KEYS.forEach(key => {
              const date = dateUtils.convertJSONDateToDateObject(chargeType[key]);
              if (date) {
                tempChargeType[key] = date;
              }
            });
            return new AddMasterChargeTypeSuccess(tempChargeType);
          }),
          catchError(error => of(new AddMasterChargeTypeError(error)))
        )
      )
    )
  );

  updateMasterChargeType$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateMasterChargeType>(EBillingActions.UPDATE_MASTER_CHARGE_TYPE),
      map((action: UpdateMasterChargeType) => action),
      switchMap(action =>
        this._billingService.updateChargeType(action.payload, action.id).pipe(
          map(chargeType => {
            const tempChargeType = JSON.parse(JSON.stringify(chargeType));

            this.DATE_KEYS.forEach(key => {
              const date = dateUtils.convertJSONDateToDateObject(chargeType[key]);
              if (date) {
                tempChargeType[key] = date;
              }
            });
            return new UpdateMasterChargeTypeSuccess(tempChargeType);
          }),
          catchError(error => of(new UpdateMasterChargeTypeFailure(error)))
        )
      )
    )
  );

  getChargeTypes = createEffect(() =>
    this._actions$.pipe(
      ofType<GetChargeTypes>(EBillingActions.GET_CHARGE_TYPES),
      map((action: GetChargeTypes) => action.payload),
      debounceTime(500),
      switchMap(payload => {
        let sortQuery = ``;
        if (payload) {
          if (payload.sortDescriptors) {
            payload.sortDescriptors.forEach(sortDescriptor => {
              sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
            });
          }
        }
        return this._billingService
          .getChargeTypes(
            payload.pageSize,
            payload.pageNumber,
            !!sortQuery ? [sortQuery] : null,
            payload.tspId,
            payload.intChargeTypeAbbr,
            payload.intChargeTypeDesc,
            payload.chargeTypeDesc,
            null,
            payload.chargeType,
            payload.masterDateEffective,
            payload.masterDateExpire,
            payload.journalEntryTypeId,
            payload.naesbChargeTypeAbbr
          )
          .pipe(
            switchMap((chargeTypeCollection: ChargeTypeCollection) =>
              of(new GetChargeTypesSuccess({ chargeTypes: chargeTypeCollection }))
            ),
            catchError(error => of(new GetChargeTypesError(error)))
          );
      })
    )
  );

  getChargeTypesDictionary = createEffect(() =>
    this._actions$.pipe(
      ofType<GetChargeTypesDictionary>(EBillingActions.GET_CHARGE_TYPES_DICTIONARY),
      switchMap(() =>
        this._chargeTypeDictionaryService.getDictionary().pipe(
          switchMap((dictionary: ChargeTypeDropdownDictionary) =>
            of(new GetChargeTypesDictionarySuccess(dictionary))
          ),
          catchError(error => of(new GetChargeTypesDictionaryError(error)))
        )
      )
    )
  );

  fetchChargeTypeById: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchChargeTypeById>(EBillingActions.FETCH_CHARGE_TYPES_BY_ID),
      map((action: FetchChargeTypeById) => action),
      switchMap(action => {
        return this._billingService.getChargeTypesById(action.chargeTypeId).pipe(
          map(chargeType => new FetchChargeTypeByIdSuccess({ chargeType })),
          catchError(error => of(new FetchChargeTypeByIdFailure(error)))
        );
      })
    )
  );

  fetchMultipleChargeTypesById: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchMultipleChargeTypesById>(EBillingActions.FETCH_MULTIPLE_CHARGE_TYPES_BY_ID),
      map((action: FetchMultipleChargeTypesById) => action),
      switchMap(payload =>
        forkJoin(
          payload.chargeTypeIds.map(chargeTypeId =>
            this._billingService
              .getChargeTypesById(chargeTypeId)
              .pipe(catchError(error => of(error)))
          )
        ).pipe(
          map(chargeTypes => {
            return new FetchMultipleChargeTypesByIdSuccess({ chargeTypes: chargeTypes });
          }),
          catchError(error => {
            return of(new FetchMultipleChargeTypesByIdFailure(error));
          })
        )
      )
    )
  );

  fetchTariffRates = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchTariffRates>(EBillingActions.FETCH_TARIFF_RATES),
      map((action: FetchTariffRates) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        let getParams = [];
        if (payload) {
          if (payload.sortDescriptors) {
            payload.sortDescriptors.forEach(sortDescriptor => {
              sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
            });
          }
          getParams = [
            payload.pageSize,
            payload.pageNumber,
            [sortQuery],
            payload.tspId,
            payload.tariffRateID,
            payload.chargeType,
            payload.dateEffective,
            payload.dateExpire,
            payload.status,
          ];
        }
        return this._billingTariffRateService.getTariffRates(...getParams).pipe(
          switchMap((tariffRateCollection: TariffRateCollection) =>
            of(
              new FetchTariffRatesSuccess({
                tariffRates: tariffRateCollection.tariffRates,
                totalTariffRateCount: tariffRateCollection.total,
              })
            )
          ),
          catchError(error => of(new FetchTariffRatesFailure(error)))
        );
      })
    )
  );

  fetchInvoices = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchInvoices>(EBillingActions.FETCH_INVOICES),
      map((action: FetchInvoices) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        if (payload) {
          if (payload.sortDescriptors) {
            payload.sortDescriptors.forEach(sortDescriptor => {
              sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
            });
          }
        }
        return this._invoiceService
          .getInvoices(
            payload.pageSize,
            payload.pageNumber,
            [sortQuery],
            payload.tspId,
            payload.accountingPeriod,
            payload.serviceRequestor,
            payload.agentName,
            payload.accountingRep
          )
          .pipe(
            switchMap((invoiceCollection: InvoiceCollection) =>
              of(
                new FetchInvoicesSuccess({
                  invoices: invoiceCollection.invoices,
                  totalInvoiceCount: invoiceCollection.total,
                })
              )
            ),
            catchError(error => of(new FetchInvoicesFailure(error)))
          );
      })
    )
  );

  fetchInvoiceById: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchInvoiceById>(EBillingActions.FETCH_INVOICE_BY_ID),
      switchMap(action =>
        this._invoiceService.getInvoicesById(action.invoiceId).pipe(
          switchMap((invoice: Invoice) => of(new FetchInvoiceByIdSuccess(invoice))),
          catchError(error => of(new FetchInvoiceByIdFailure(error)))
        )
      )
    )
  );

  fetchInvoiceExceptions = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchInvoiceExceptions>(EBillingActions.FETCH_INVOICES_EXCEPTIONS),
      map((action: FetchInvoiceExceptions) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        if (payload) {
          if (payload.sortDescriptors) {
            payload.sortDescriptors.forEach(sortDescriptor => {
              sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
            });
          }
        }
        return this._billingInvoicesErrorsService
          .getInvoiceErrors(
            payload.pageSize,
            payload.pageNumber,
            [sortQuery],
            payload.tspId,
            payload.accountingPeriod,
            payload.exceptionType
          )
          .pipe(
            switchMap((invoiceCollection: InvoiceErrorCollection) =>
              of(
                new FetchInvoiceExceptionsSuccess({
                  invoiceErrors: invoiceCollection.invoiceErrors,
                  total: invoiceCollection.total,
                })
              )
            ),
            catchError(error => of(new FetchInvoiceExceptionsFailure(error)))
          );
      })
    )
  );

  fetchTariffRateById: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchTariffRateById>(EBillingActions.FETCH_TARIFF_RATE_BY_ID),
      switchMap(action =>
        this._billingTariffRateService.getTariffRateById(action.tariffRateId).pipe(
          switchMap((tariffRate: TariffRate) => of(new FetchTariffRateByIdSuccess(tariffRate))),
          catchError(error => of(new FetchTariffRateByIdFailure(error)))
        )
      )
    )
  );

  getTariffRateDictionary = createEffect(() =>
    this._actions$.pipe(
      ofType<GetTariffRateDictionary>(EBillingActions.GET_TARIFF_RATE_DICTIONARY),
      switchMap(() =>
        this._tariffRateDictionaryService.getTariffRatesDictionary().pipe(
          switchMap((dictionary: TariffRateDropdownDictionary) =>
            of(new GetTariffRateDictionarySuccess(dictionary))
          ),
          catchError(error => of(new GetTariffRateDictionaryError(error)))
        )
      )
    )
  );

  fetchInvoiceFilters = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchInvoiceFilters>(EBillingActions.FETCH_INVOICE_FILTERS),
      map((action: FetchInvoiceFilters) => action.payload),
      switchMap(({ tspId }) =>
        // having all filter options in 1 api call is taking too long as billing API is doing the lookup into all invoice transactions
        // breaking the call down to multiple calls for specific filters allow concurrent fetches thus eliminate the time out issue
        // refer to GMS-52372
        forkJoin({
          accountingRepUsers: this._billingInvoicesFilterValuesService.getInvoiceFilters(tspId, 'AccountingRepUsers').pipe(map(filteredCollection => filteredCollection.accountingRepUsers)),
          agents: this._billingInvoicesFilterValuesService.getInvoiceFilters(tspId, 'Agents').pipe(map(filteredCollection => filteredCollection.agents)),
          chargeTypes: this._billingInvoicesFilterValuesService.getInvoiceFilters(tspId, 'ChargeTypes').pipe(map(filteredCollection => filteredCollection.chargeTypes)),
          entities: this._billingInvoicesFilterValuesService.getInvoiceFilters(tspId, 'Entities').pipe(map(filteredCollection => filteredCollection.entities)),
          rateSchedules: this._billingInvoicesFilterValuesService.getInvoiceFilters(tspId, 'RateSchedules').pipe(map(filteredCollection => filteredCollection.rateSchedules)),
          replacementReleaseCodes: this._billingInvoicesFilterValuesService.getInvoiceFilters(tspId, 'ReplacementReleaseCodes').pipe(map(filteredCollection => filteredCollection.replacementReleaseCodes)),
          serviceRequestContracts: this._billingInvoicesFilterValuesService.getInvoiceFilters(tspId, 'ServiceRequestContracts').pipe(map(filteredCollection => filteredCollection.serviceRequestContracts)),
        }).pipe(
          map( invoiceFilterCollection => {
            return new FetchInvoiceFiltersSuccess({ invoiceFilterCollection })
          }),
          catchError(error => {
            return of(new FetchInvoiceFiltersFailure({ error }));
          })
        )
      )
    )
  );

  searchChargeTypes: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SearchChargeTypes>(EBillingActions.SEARCH_CHARGE_TYPES),
      map((action: SearchChargeTypes) => action.payload),
      debounceTime(500),
      switchMap(payload => {
        return this._billingService
          .getChargeTypes(
            300,
            null,
            payload.sortBy,
            payload.tspId,
            null,
            null,
            null,
            null,
            payload.chargeType
          )
          .pipe(
            map(
              chargeTypeCollection =>
                new SearchChargeTypesSuccess({
                  chargeTypes: chargeTypeCollection.chargeTypes.reduce(
                    (results, chargeType) => {
                      if (!results.keys.has(chargeType.id)) {
                        results.keys.add(chargeType.id);
                        results.allUnique.push(chargeType);
                      }
                      return results;
                    },
                    { allUnique: new Array<ChargeTypeSummary>(), keys: new Set<number>() }
                  ).allUnique,
                })
            ),
            catchError(error => of(new SearchChargeTypesFailure(error)))
          );
      })
    )
  );

  searchFlowRulesChargeTypes: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SearchDisqualificationFlowChargeTypes>(
        EBillingActions.SEARCH_DISQUALIFICATION_FLOW_CHARGE_TYPES
      ),
      map((action: SearchDisqualificationFlowChargeTypes) => action.payload),
      debounceTime(500),
      switchMap(payload => {
        return this._billingService
          .getChargeTypes(
            300,
            null,
            payload.sortBy,
            payload.tspId,
            null,
            null,
            null,
            null,
            payload.chargeType,
            null,
            null,
            '1|3',
            'RSV|SRC|SRW'
          )
          .pipe(
            map(
              chargeTypeCollection =>
                new SearchDisqualificationFlowChargeTypesSuccess({
                  chargeTypes: chargeTypeCollection.chargeTypes.reduce(
                    (results, chargeType) => {
                      if (!results.keys.has(chargeType.id)) {
                        results.keys.add(chargeType.id);
                        results.allUnique.push(chargeType);
                      }
                      return results;
                    },
                    { allUnique: new Array<MasterChargeType>(), keys: new Set<number>() }
                  ).allUnique,
                })
            ),
            catchError(error => of(new SearchChargeTypesFailure(error)))
          );
      })
    )
  );

  searchSegmentationTotalsChargeTypes: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SearchSegmentationTotalsChargeTypes>(
        EBillingActions.SEARCH_SEGMENTATION_TOTALS_CHARGE_TYPES
      ),
      map((action: SearchSegmentationTotalsChargeTypes) => action.payload),
      debounceTime(500),
      switchMap(payload => {
        return this._billingService
          .getChargeTypes(
            300,
            null,
            payload.sortBy,
            payload.tspId,
            null,
            null,
            null,
            null,
            payload.chargeType,
            null,
            null,
            '1|3'
          )
          .pipe(
            map(
              chargeTypeCollection =>
                new SearchSegmentationTotalsChargeTypesSuccess({
                  chargeTypes: chargeTypeCollection.chargeTypes.reduce(
                    (results, chargeType) => {
                      if (!results.keys.has(chargeType.id)) {
                        results.keys.add(chargeType.id);
                        results.allUnique.push(chargeType);
                      }
                      return results;
                    },
                    { allUnique: new Array<MasterChargeType>(), keys: new Set<number>() }
                  ).allUnique,
                })
            ),
            catchError(error => of(new SearchChargeTypesFailure(error)))
          );
      })
    )
  );

  addTariffRate$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddTariffRate>(EBillingActions.ADD_TARIFF_RATE),
      map((action: AddTariffRate) => action),
      switchMap(action =>
        this._billingTariffRateService.addTariffRates(action.payload).pipe(
          map(tariffRate => new AddTariffRateSuccess(tariffRate)),
          catchError(error => of(new AddTariffRateFailure(error)))
        )
      )
    )
  );

  updateTariffRate$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateTariffRate>(EBillingActions.UPDATE_TARIFF_RATE),
      map((action: UpdateTariffRate) => action),
      switchMap(action =>
        this._billingTariffRateService.putTariffRateById(action.payload, action.tariffRateId).pipe(
          map(tariffRate => new UpdateTariffRateSuccess(tariffRate)),
          catchError(error => of(new UpdateTariffRateFailure(error)))
        )
      )
    )
  );

  fetchChargeTypesDetails$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchChargeTypesDetails>(EBillingActions.FETCH_CHARGE_TYPES_DETAILS),
      map((action: FetchChargeTypesDetails) => action),
      debounceTime(500),
      switchMap(action => {
        let sortQuery = '';
        if (action.payload.sortDescriptors) {
          action.payload.sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }

        return this._billingChargeTypesDetailsService
          .getChargeTypeDetails(
            action.payload.pageSize,
            action.payload.pageNumber,
            [sortQuery],
            action.payload.tspId,
            action.payload.chargeTypeDateEffective,
            action.payload.chargeTypeDateExpire,
            action.payload.chargeBasis,
            action.payload.transactionType,
            action.payload.pipelineSystem,
            action.payload.rateSchedule,
            action.payload.internalAbbreviation,
            action.payload.internalDescription,
            action.payload.chargeTypeDescription,
            action.payload.journalEntryTypeID,
            action.payload.ignorePipeline
          )
          .pipe(
            map(chargeTypesDetails => new FetchChargeTypesDetailsSuccess(chargeTypesDetails)),
            catchError(error => of(new FetchChargeTypesDetailsFailure(error)))
          );
      })
    )
  );

  getInvoiceDetails = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchInvoiceTransactionDetails>(EBillingActions.FETCH_INVOICE_TRANSACTION_DETAILS),
      map((action: FetchInvoiceTransactionDetails) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        if (payload) {
          if (payload.sortDescriptors) {
            payload.sortDescriptors.forEach(sortDescriptor => {
              sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
            });
          }
        }
        return this._billingInvoiceDetailsService
          .getInvoiceDetails(
            payload.view as 'Daily' | 'Monthly' | 'Contract',
            payload.pageSize || null,
            payload.pageNumber || null,
            payload.accountingPeriod,
            payload.productionPeriod,
            payload.beginTrans,
            payload.endTrans,
            [sortQuery],
            payload.tspId,
            payload.invoiceId,
            payload.serviceRequester,
            payload.agentName,
            payload.rateSchedule,
            payload.svcReqK,
            payload.intChrgType,
            payload.tt,
            payload.chargeIndicator,
            payload.chargeType,
            payload.rrCode,
            payload.priceType,
            payload.rateType,
            payload.recLoc,
            payload.recLocName,
            payload.recZone,
            payload.delLoc,
            payload.delLocName,
            payload.delZone,
            payload.chargeTypeOperator as
              | 'Between'
              | 'LessThanOrEqual'
              | 'GreaterThanOrEqual'
              | 'Equal',
            payload.chargeTypeOne,
            payload.chargeTypeTwo,
            null,
            payload.accountId,
            payload.accountTypeId,
            payload.miscNotesChargeTypeDescriptions
          )
          .pipe(
            switchMap((invoiceDetailCollection: InvoiceDetailCollection) =>
              of(
                new FetchInvoiceTransactionDetailsSuccess({
                  invoiceDetails: invoiceDetailCollection,
                })
              )
            ),
            catchError(error => of(new FetchInvoiceTransactionDetailsFailure(error)))
          );
      })
    )
  );

  getInvoiceDetailsTotal = createEffect(() =>
  this._actions$.pipe(
    ofType<FetchInvoiceTransactionDetailsTotal>(EBillingActions.FETCH_INVOICE_TRANSACTION_DETAILS_TOTAL),
    map((action: FetchInvoiceTransactionDetailsTotal) => action.payload),
    switchMap(payload => {
      return this._billingInvoiceDetailsTotalService.getInvoiceDetailsTotal(
          payload.view as 'Daily' | 'Monthly' | 'Contract',
          payload.accountingPeriod,
          payload.serviceRequester,
        )
        .pipe(
          switchMap((invoiceDetailTotalCollection: InvoiceDetailTotalCollection) =>
            of(
              new FetchInvoiceTransactionDetailsTotalSuccess(invoiceDetailTotalCollection)
            )
          ),
          catchError(error => of(new FetchInvoiceTransactionDetailsFailure(error)))
        );
    })
  )
);


  fetchEarliestOpenAccountingPeriod: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchEarliestOpenAccountingPeriod>(
        EBillingActions.FETCH_EARLIEST_OPEN_ACCOUNTING_PERIOD
      ),
      switchMap(action => {
        return this._billingInvoicesAccountingPeriodService
          .getOpenAccountingPeriod('earliest', action.payload.tspId)
          .pipe(
            map(accountingPeriod => new FetchEarliestOpenAccountingPeriodSuccess(accountingPeriod)),
            catchError(error => of(new FetchEarliestOpenAccountingPeriodFailure(error)))
          );
      })
    )
  );

  fetchAllOpenAccountingPeriods: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchAllOpenAccountingPeriods>(EBillingActions.FETCH_ALL_OPEN_ACCOUNTING_PERIODS),
      switchMap(({ payload = {} }) => {
        return this._billingInvoicesAccountingPeriods.getOpenAccountingPeriods(payload.tspId).pipe(
          map(accountingPeriods => new FetchAllOpenAccountingPeriodsSuccess(accountingPeriods)),
          catchError(error => of(new FetchAllOpenAccountingPeriodsFailure(error)))
        );
      })
    )
  );

  getTariffRateDetails = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchTariffRateDetails>(EBillingActions.FETCH_TARIFF_RATE_DETAILS),
      map((action: FetchTariffRateDetails) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        let getParams = [];
        if (payload) {
          if (payload.sortDescriptors) {
            payload.sortDescriptors.forEach(sortDescriptor => {
              sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
            });
          }
          getParams = [
            payload.pageSize,
            payload.pageNumber,
            [sortQuery],
            payload.tariffRateID,
            payload.tspId,
            payload.rateDescription,
            payload.internalChargeTypeDescription,
            payload.internalChargeTypeAbbreviation,
            payload.rateSchedule,
            payload.dateEffective,
            payload.dateExpire,
            payload.chargeBasis,
            payload.rateType,
            payload.priceType,
            payload.chargePeriod,
            payload.rateComponent,
            payload.rateZoneDependency,
            payload.seasonal,
            payload.discountable,
            payload.userId,
            undefined,
            payload.status,
          ];
        }
        return this._billingTariffRateService.getTariffRateDetails(...getParams).pipe(
          switchMap((tariffRateDetailCollection: TariffRateDetailCollection) =>
            of(
              new FetchTariffRateDetailsSuccess({
                tarrifRateDetails: tariffRateDetailCollection,
              })
            )
          ),
          catchError(error => of(new FetchTariffRateDetailsFailure(error)))
        );
      })
    )
  );
  fetchExportChargeTypesDetails$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchExportChargeTypesDetails>(EBillingActions.FETCH_EXPORT_CHARGE_TYPES_DETAILS),
      map((action: FetchExportChargeTypesDetails) => action),
      switchMap(action => {
        let sortQuery = '';
        if (action.payload.sortDescriptors) {
          action.payload.sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }
        return this._billingChargeTypesDetailsService
          .getChargeTypeDetails(
            action.payload.pageSize,
            action.payload.pageNumber,
            [sortQuery],
            action.payload.tspId,
            action.payload.chargeTypeDateEffective,
            action.payload.chargeTypeDateExpire,
            action.payload.chargeBasis,
            action.payload.transactionType,
            action.payload.pipelineSystem,
            action.payload.rateSchedule,
            action.payload.internalAbbreviation,
            action.payload.internalDescription,
            action.payload.chargeTypeDescription,
            action.payload.journalEntryTypeID
          )
          .pipe(
            map(chargeTypesDetails => new FetchExportChargeTypesDetailsSuccess(chargeTypesDetails)),
            catchError(error => of(new FetchExportChargeTypesDetailsFailure(error)))
          );
      })
    )
  );

  fetchExportInvoiceExceptions$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchExportInvoiceExceptions>(EBillingActions.FETCH_EXPORT_INVOICE_EXCEPTIONS),
      map((action: FetchExportInvoiceExceptions) => action.payload),
      switchMap(payload => {
        return this._billingInvoicesErrorsService
          .getInvoiceErrors(
            payload.pageSize,
            payload.pageNumber,
            null,
            payload.tspId,
            payload.accountingPeriod,
            payload.exceptionType
          )
          .pipe(
            switchMap((invoiceCollection: InvoiceErrorCollection) =>
              of(
                new FetchExportInvoiceExceptionsSuccess({
                  invoiceExceptions: invoiceCollection.invoiceErrors,
                })
              )
            ),
            catchError(error => of(new FetchExportInvoiceExceptionsFailure(error)))
          );
      })
    )
  );

  fetchExportTariffRateDetails = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchExportTariffRateDetails>(EBillingActions.FETCH_EXPORT_TARIFF_RATE_DETAILS),
      map((action: FetchExportTariffRateDetails) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        let getParams = [];
        if (payload) {
          if (payload.sortDescriptors) {
            payload.sortDescriptors.forEach(sortDescriptor => {
              sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
            });
          }
          getParams = [
            payload.pageSize,
            payload.pageNumber,
            [sortQuery],
            payload.tariffRateID,
            payload.tspId,
            payload.rateDescription,
            payload.internalChargeTypeDescription,
            payload.internalChargeTypeAbbreviation,
            payload.rateSchedule,
            payload.dateEffective,
            payload.dateExpire,
            payload.chargeBasis,
            payload.rateType,
            payload.priceType,
            payload.chargePeriod,
            payload.rateComponent,
            payload.rateZoneDependency,
            payload.seasonal,
            payload.discountable,
            payload.userId,
            undefined,
            payload.status,
          ];
        }
        return this._billingTariffRateService.getTariffRateDetails(...getParams).pipe(
          switchMap((tariffRateDetailCollection: TariffRateDetailCollection) =>
            of(
              new FetchExportTariffRateDetailsSuccess({
                exportTarrifRateDetails: tariffRateDetailCollection,
              })
            )
          ),
          catchError(error => of(new FetchExportTariffRateDetailsFailure(error)))
        );
      })
    )
  );

  fetchTspChargeTypeById: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchTspChargeTypeById>(EBillingActions.FETCH_TSP_CHARGE_TYPE),
      map((action: FetchTspChargeTypeById) => action),
      switchMap(action => {
        return this._billingTspChargeTypeService.getTspChargeTypesById(action.tspChargeTypeId).pipe(
          map(
            tspChargeType =>
              new FetchTspChargeTypeByIdSuccess({
                tspChargeType: tspChargeType,
              })
          ),
          catchError(error => of(new FetchTspChargeTypeByIdFailure(error)))
        );
      })
    )
  );

  fetchMultipleTspChargeTypesById: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchMultipleTspChargeTypesById>(
        EBillingActions.FETCH_MULTIPLE_TSP_CHARGE_TYPES_BY_ID
      ),
      map((action: FetchMultipleTspChargeTypesById) => action.tspChargeTypeIds),
      switchMap(tspChargeTypeIds =>
        forkJoin(
          tspChargeTypeIds.map(tspChargeTypeId =>
            this._billingTspChargeTypeService.getTspChargeTypesById(tspChargeTypeId)
          )
        ).pipe(
          map((tspChargeTypes: TspChargeTypeDetail[]) => {
            return new FetchMultipleTspChargeTypesByIdSuccess({ tspChargeTypes: tspChargeTypes });
          }),
          catchError(error => {
            return of(new FetchMultipleTspChargeTypesByIdFailure(error));
          })
        )
      )
    )
  );

  fetchTspReservationChargeTypes: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchTspReservationChargeTypes>(EBillingActions.FETCH_TSP_RESERVATION_CHARGE_TYPES),
      map((action: FetchTspReservationChargeTypes) => action.payload),
      switchMap(payload => {
        return this._billingTspChargeTypeReservationService
          .getTspChargeTypesByCategory(
            (payload.category as 'reservation') || 'supplemental',
            payload.pageSize || null,
            payload.pageNumber || null,
            payload.sortBy,
            payload.tspId,
            payload.searchPhrase
          )
          .pipe(
            map(
              tspChargeTypes =>
                new FetchTspReservationChargeTypesSuccess({
                  tspReservationChargeTypes: tspChargeTypes,
                })
            ),
            catchError(error => of(new FetchTspReservationChargeTypesFailure(error)))
          );
      })
    )
  );

  fetchInvoiceGroupsById: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchInvoiceGroupsById>(EBillingActions.FETCH_INVOICE_GROUPS_BY_ID),
      switchMap(action =>
        this._billingInvoiceGroupService.getInvoiceGroupsByEntityId(action.entityId).pipe(
          switchMap((invoiceGroupCollection: InvoiceGroupCollection) =>
            of(new FetchInvoiceGroupsByIdSuccess(invoiceGroupCollection))
          ),
          catchError(error => of(new FetchInvoiceGroupsByIdFailure(error)))
        )
      )
    )
  );

  addInvoiceGroup: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddInvoiceGroup>(EBillingActions.ADD_INVOICE_GROUP),
      map((action: AddInvoiceGroup) => action),
      switchMap(action =>
        this._billingInvoiceGroupService.addInvoiceGroup(action.payload).pipe(
          map((invoiceGroup: InvoiceGroup) => new AddInvoiceGroupSuccess(invoiceGroup)),
          catchError((error: Error) => of(new AddInvoiceGroupFailure(error)))
        )
      )
    )
  );

  updateInvoiceGroup: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateInvoiceGroup>(EBillingActions.UPDATE_INVOICE_GROUP),
      map((action: UpdateInvoiceGroup) => action),
      switchMap(action =>
        this._billingInvoiceGroupService
          .putInvoiceGroupById(action.payload, action.invoiceGroupId)
          .pipe(
            map((invoiceGroup: InvoiceGroup) => new UpdateInvoiceGroupSuccess(invoiceGroup)),
            catchError((error: Error) => of(new UpdateInvoiceGroupFailure(error)))
          )
      )
    )
  );

  fetchInvoiceGroupById: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchInvoiceGroupById>(EBillingActions.FETCH_INVOICE_GROUP_BY_ID),
      switchMap(action =>
        this._billingInvoiceGroupService.getInvoiceGroupById(action.invoiceGroupId).pipe(
          switchMap((invoiceGroup: InvoiceGroup) =>
            of(new FetchInvoiceGroupByIdSuccess(invoiceGroup))
          ),
          catchError((error: Error) => of(new FetchInvoiceGroupByIdFailure(error)))
        )
      )
    )
  );

  UpdateTariffRateWorkflow: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateTariffRateWorkflow>(EBillingActions.UPDATE_TARIFF_RATE_WORKFLOW),
      map((action: UpdateTariffRateWorkflow) => action),
      switchMap(action =>
        this._billingTariffRateService
          .putTariffRateVersionAction(
            action.payload.tariffRateVersionWorkflowAction,
            action.payload.tariffRateId,
            action.payload.tariffRateVersionId
          )
          .pipe(
            map(response => new UpdateTariffRateWorkflowSuccess()),
            catchError((error: Error) => of(new UpdateTariffRateWorkflowFailure(error)))
          )
      )
    )
  );

  fetchIndexRate: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchIndexRate>(EBillingActions.FETCH_INDEX_RATE),
      switchMap(action =>
        this._billingIndexRateService
          .getIndexRate(action.payload.rateId, action.payload.productionPeriod)
          .pipe(
            switchMap((indexRate: IndexRateDetail) => of(new FetchIndexRateSuccess(indexRate))),
            catchError(error => of(new FetchIndexRateFailure(error)))
          )
      )
    )
  );

  fetchCalculatedPriceTypes: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchCalculatedPriceTypes>(EBillingActions.FETCH_CALCULATED_PRICE_TYPES),
      switchMap(action =>
        this._billingCalculatedPriceTypesService.getCalculatedPriceTypes(action.payload.tspId).pipe(
          switchMap((CalculatedPriceTypes: CalculatedPriceTypeCollection) =>
            of(new FetchCalculatedPriceTypesSuccess(CalculatedPriceTypes))
          ),
          catchError(error => of(new FetchCalculatedPriceTypesFailure(error)))
        )
      )
    )
  );

  fetchIndexRates: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchIndexRates>(EBillingActions.FETCH_INDEX_RATES),
      map(action => action.payload),
      switchMap(payload =>
        this._billingIndexRatesService
          .getIndexRates(payload.tspId, payload.capacityReleaseFlag)
          .pipe(
            switchMap((indexRates: IndexRateSummaryCollection) =>
              of(new FetchIndexRatesSuccess(indexRates))
            ),
            catchError(error => of(new FetchIndexRatesFailure(error)))
          )
      )
    )
  );

  addIndexRate$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddIndexRate>(EBillingActions.ADD_INDEX_RATE),
      map((action: AddIndexRate) => action),
      switchMap(action =>
        this._billingIndexRateService
          .addIndexRate(
            action.payload.indexRate,
            action.payload.rateId,
            action.payload.productionPeriod
          )
          .pipe(
            mergeMap(indexRate => [
              new AddIndexRateSuccess(indexRate),
              new FetchIndexRate({
                rateId: action.payload.rateId,
                productionPeriod: action.payload.productionPeriod,
              }),
            ]),
            catchError(error => of(new AddIndexRateFailure(error)))
          )
      )
    )
  );

  //TODO: sort doesn't work this may be important for this dropdown.
  fetchAccounts: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchAccounts>(EBillingActions.FETCH_ACCOUNTS),
      map((action: FetchAccounts) => action.payload),
      switchMap((payload: FetchAccountsPayload) => {
        return this._billingAccountAllService
          .getAccountsAll(
            payload.pageSize,
            payload.pageNumber,
            payload.sortBy,
            payload.tspId,
            payload.accountTypeId,
            payload.entityId,
            payload.entityIds,
            payload.accountIds,
            payload.viewBy,
            payload.searchPhrase
          )
          .pipe(
            switchMap((accounts: AccountAllDetailCollection) =>
              of(new FetchAccountsSuccess(accounts))
            ),
            catchError(error => of(new FetchAccountsFailure(error)))
          );
      })
    )
  );

  fetchInvoiceAttachments: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchInvoiceAttachments>(EBillingActions.FETCH_INVOICE_ATTACHMENTS),
      map((action: FetchInvoiceAttachments) => action.payload),
      switchMap((payload: FetchInvoiceAttachmentsPayload) =>
        this._billingInvoicesAttachmentsService
          .getInvoiceAttachments(
            convertAccountPeriodToDate(payload.accountPeriod),
            payload.tspId,
            payload.svcReqId
          )
          .pipe(
            map(
              (attachmentCollection: InvoiceAttachmentCollection) =>
                new FetchInvoiceAttachmentsSuccess(attachmentCollection)
            ),
            catchError(error => of(new FetchInvoiceAttachmentsFailure(error)))
          )
      )
    )
  );

  deleteInvoiceAttachment: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<DeleteInvoiceAttachment>(EBillingActions.DELETE_INVOICE_ATTACHMENT),
      map((action: DeleteInvoiceAttachment) => action.attachmentId),
      switchMap((attachmentId: number) =>
        this._billingInvoicesAttachmentsService.deleteInvoiceAttachment(attachmentId).pipe(
          map(() => new DeleteInvoiceAttachmentSuccess()),
          catchError(error => of(new DeleteInvoiceAttachmentFailure(error)))
        )
      )
    )
  );

  uploadInvoiceAttachment: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UploadInvoiceAttachment>(EBillingActions.UPLOAD_INVOICE_ATTACHMENT),
      map((action: UploadInvoiceAttachment) => action.payload),
      switchMap((payload: IUploadInvoiceAttachmentPayload) =>
        this._billingInvoicesAttachmentsService
          .postInvoiceAttachment(
            payload.accountingPeriod,
            payload.description,
            payload.file,
            payload.svcReqId,
            payload.svcReqName,
            payload.tspId,
            payload.invoiceGroupId
          )
          .pipe(
            map(() => new UploadInvoiceAttachmentSuccess()),
            catchError(error => of(new UploadInvoiceAttachmentFailure(error)))
          )
      )
    )
  );

  DownloadInvoicePackage: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<DownloadInvoicePackage>(EBillingActions.DOWNLOAD_INVOICE_PACKAGE),
      map((action: DownloadInvoicePackage) => action.invoiceId),
      switchMap((invoiceId: number) =>
        this._invoiceService.getInvoicePackage(invoiceId).pipe(
          map(pdfFileLink => {
            window.open(pdfFileLink, '_blank');
            return new DownloadInvoicePackageSuccess();
          }),
          catchError(error => of(new DownloadInvoicePackageFailure(error)))
        )
      )
    )
  );

  GetPalDailyRate: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetPalDailyBillingRate>(EBillingActions.GET_PAL_DAILY_BILLING_RATE),
      map(action => action.payload),
      switchMap(({ contractId, request, rate }) => {
        return this._billingPalDailyRateService.postPalDailyRate(request).pipe(
          map(
            ({ dailyRate }) => new GetPalDailyBillingRateSuccess({ contractId, rate: dailyRate })
          ),
          catchError(error => of(new GetPalDailyBillingRateFailure(error, contractId, rate)))
        );
      })
    )
  );

  GetAccountingPeriods: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAccountingPeriods>(EBillingActions.GET_ACCOUNTING_PERIODS),
      switchMap(action =>
        this._billingInvoicesAllAccountingPeriodsService
          .getAccountingPeriods(action.payload.tspId)
          .pipe(
            map(
              (accountingPeriodCollection: AccountingPeriodCollection) =>
                new GetAccountingPeriodsSuccess(accountingPeriodCollection.accountingPeriods)
            ),
            catchError(error => of(new GetAccountingPeriodsFailure(error)))
          )
      )
    )
  );

  fetchCapRelIndexRates: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchCapRelIndexRates>(EBillingActions.FETCH_CAP_REL_INDEX_RATES),
      map(action => action.payload),
      switchMap(payload =>
        this._capRelIndexRatesService.getCapRelIndexRates(payload.tspId).pipe(
          switchMap((capRelIndexRates: IndexRateSummaryCollection) =>
            of(new FetchCapRelIndexRatesSuccess(capRelIndexRates))
          ),
          catchError(error => of(new FetchCapRelIndexRatesFailure(error)))
        )
      )
    )
  );

  fetchDeliveryZones: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchDeliveryZones>(EBillingActions.FETCH_DELIVERY_ZONES),
      switchMap(action => {
        return this._billingZoneService.getZones(action.tspId).pipe(
          map(zones => new FetchDeliveryZonesSuccess(zones)),
          catchError(error => of(new FetchDeliveryZonesFailure({ error: error })))
        );
      })
    )
  );

  fetchDeliveryFuelZones: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchDeliveryFuelZones>(EBillingActions.FETCH_DELIVERY_FUEL_ZONES),
      switchMap(action => {
        return this._billingZoneService.getZones(action.tspId, action.fuelZones).pipe(
          map(fuelZones => new FetchDeliveryFuelZonesSuccess(fuelZones)),
          catchError(error => of(new FetchDeliveryFuelZonesFailure({ error: error })))
        );
      })
    )
  );

  fetchZoneToZone: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchZoneToZone>(EBillingActions.FETCH_ZONE_TO_ZONE),
      switchMap(action => {
        return this._billingZoneService.getZoneToZone(action.tspId).pipe(
          map((zones: Array<ZoneToZone>) => new FetchZoneToZoneSuccess(zones)),
          catchError(error => of(new FetchZoneToZoneFailure({ error: error })))
        );
      })
    )
  );

  fetchFuelZoneToFuelZone: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchFuelZoneToFuelZone>(EBillingActions.FETCH_FUEL_ZONE_TO_FUEL_ZONE),
      switchMap(action => {
        return this._billingZoneService.getFuelZoneToZone(action.tspId).pipe(
          map((zones: Array<ZoneToZone>) => new FetchFuelZoneToFuelZoneSuccess(zones)),
          catchError(error => of(new FetchFuelZoneToFuelZoneFailure({ error: error })))
        );
      })
    )
  );

  exportInvoiceTransactions: Observable<any> = createEffect(() =>
  this._actions$.pipe(
    ofType<ExportInvoiceTransactions>(EBillingActions.EXPORT_INVOICE_TRANSACTIONS),
    map((action: ExportInvoiceTransactions) => action.payload),
    switchMap(payload => {
      let sortQuery = ``;
      if (payload) {
        if (payload.sortDescriptors) {
          payload.sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }
      }
      return this._billingInvoiceDetailsService
      .getInvoiceDetails(
        payload.view as 'Daily' | 'Monthly' | 'Contract',
        payload.pageSize || null,
        payload.pageNumber || null,
        payload.accountingPeriod,
        payload.productionPeriod,
        payload.beginTrans,
        payload.endTrans,
        [sortQuery],
        payload.tspId,
        payload.invoiceId,
        payload.serviceRequester,
        payload.agentName,
        payload.rateSchedule,
        payload.svcReqK,
        payload.intChrgType,
        payload.tt,
        payload.chargeIndicator,
        payload.chargeType,
        payload.rrCode,
        payload.priceType,
        payload.rateType,
        payload.recLoc,
        payload.recLocName,
        payload.recZone,
        payload.delLoc,
        payload.delLocName,
        payload.delZone,
        payload.chargeTypeOperator as
          | 'Between'
          | 'LessThanOrEqual'
          | 'GreaterThanOrEqual'
          | 'Equal',
        payload.chargeTypeOne,
        payload.chargeTypeTwo,
        null,
        payload.accountId,
        payload.accountTypeId,
        null,
        true
      )
      .pipe(
        map((result) => new ExportInvoiceTransactionsSuccess()),
        catchError(error => of(new ExportInvoiceTransactionsFailure({ error: error})))
      )
    })
  ));
}
